import agileAdvisor from "@assets/textsAssets/textIcons/agileAdvisor_icon.png";
import behavior from "@assets/textsAssets/textIcons/behavior_icon.png";
import clientValue from "@assets/textsAssets/textIcons/clientValue_icon.png";
import coaching from "@assets/textsAssets/textIcons/coaching_icon.png";
import continuousImprovement from "@assets/textsAssets/textIcons/continuousImprovement_icon.png";
import discipline from "@assets/textsAssets/textIcons/discipline_icon.png";
import effortEstimation from "@assets/textsAssets/textIcons/effortEstimation_icon.png";
import facilitation from "@assets/textsAssets/textIcons/facilitation_icon.png";
import facilitationResolution from "@assets/textsAssets/textIcons/facilitationResolution_icon.png";
import git from "@assets/textsAssets/textIcons/git_icon.png";
import gsap from "@assets/textsAssets/textIcons/gsap_icon.png";
import helpingTeam from "@assets/textsAssets/textIcons/helpingTeam_icon.png";
import htmlcss from "@assets/textsAssets/textIcons/htmlcss_icon.png";
import js from "@assets/textsAssets/textIcons/js_icon.png";
import mediation from "@assets/textsAssets/textIcons/mediation_icon.png";
import obstacleManagement from "@assets/textsAssets/textIcons/obstacleManagement_icon.png";
import reactangular from "@assets/textsAssets/textIcons/reactangular_icon.png";
import scrumAuditor from "@assets/textsAssets/textIcons/scrumAuditor_icon.png";
import scrumCoach from "@assets/textsAssets/textIcons/scrumCoach_icon.png";
import scrumMasterTech from "@assets/textsAssets/textIcons/scrumMasterTech_icon.png";
import scrumTrainer from "@assets/textsAssets/textIcons/scrumTrainer_icon.png";
import serventLeader from "@assets/textsAssets/textIcons/serventLeader_icon.png";
import sql from "@assets/textsAssets/textIcons/sql_icon.png";
import teaching from "@assets/textsAssets/textIcons/teaching_icon.png";
import technicalCoaching from "@assets/textsAssets/textIcons/technicalCoaching_icon.png";
import technicalExcellence from "@assets/textsAssets/textIcons/technicalExcellence_icon.png";
import trustCredibility from "@assets/textsAssets/textIcons/trustCredibility_icon.png";
import understandingTech from "@assets/textsAssets/textIcons/understandingTech_icon.png";
import linkedin from "@assets/textsAssets/textIcons/linkedin_icon.png";
import twitter from "@assets/textsAssets/textIcons/twitter_icon.png";

import agileAdvisorWebp from "@assets/textsAssets/textIcons/agileAdvisor_icon.webp";
import behaviorWebp from "@assets/textsAssets/textIcons/behavior_icon.webp";
import clientValueWebp from "@assets/textsAssets/textIcons/clientValue_icon.webp";
import coachingWebp from "@assets/textsAssets/textIcons/coaching_icon.webp";
import continuousImprovementWebp from "@assets/textsAssets/textIcons/continuousImprovement_icon.webp";
import disciplineWebp from "@assets/textsAssets/textIcons/discipline_icon.webp";
import effortEstimationWebp from "@assets/textsAssets/textIcons/effortEstimation_icon.webp";
import facilitationWebp from "@assets/textsAssets/textIcons/facilitation_icon.webp";
import facilitationResolutionWebp from "@assets/textsAssets/textIcons/facilitationResolution_icon.webp";
import gitWebp from "@assets/textsAssets/textIcons/git_icon.webp";
import gsapWebp from "@assets/textsAssets/textIcons/gsap_icon.webp";
import helpingTeamWebp from "@assets/textsAssets/textIcons/helpingTeam_icon.webp";
import htmlcssWebp from "@assets/textsAssets/textIcons/htmlcss_icon.webp";
import jsWebp from "@assets/textsAssets/textIcons/js_icon.webp";
import mediationWebp from "@assets/textsAssets/textIcons/mediation_icon.webp";
import obstacleManagementWebp from "@assets/textsAssets/textIcons/obstacleManagement_icon.webp";
import reactangularWebp from "@assets/textsAssets/textIcons/reactangular_icon.webp";
import scrumAuditorWebp from "@assets/textsAssets/textIcons/scrumAuditor_icon.webp";
import scrumCoachWebp from "@assets/textsAssets/textIcons/scrumCoach_icon.webp";
import scrumMasterTechWebp from "@assets/textsAssets/textIcons/scrumMasterTech_icon.webp";
import scrumTrainerWebp from "@assets/textsAssets/textIcons/scrumTrainer_icon.webp";
import serventLeaderWebp from "@assets/textsAssets/textIcons/serventLeader_icon.webp";
import sqlWebp from "@assets/textsAssets/textIcons/sql_icon.webp";
import teachingWebp from "@assets/textsAssets/textIcons/teaching_icon.webp";
import technicalCoachingWebp from "@assets/textsAssets/textIcons/technicalCoaching_icon.webp";
import technicalExcellenceWebp from "@assets/textsAssets/textIcons/technicalExcellence_icon.webp";
import trustCredibilityWebp from "@assets/textsAssets/textIcons/trustCredibility_icon.webp";
import understandingTechWebp from "@assets/textsAssets/textIcons/understandingTech_icon.webp";
import linkedinWebp from "@assets/textsAssets/textIcons/linkedin_icon.webp";
import twitterWebp from "@assets/textsAssets/textIcons/twitter_icon.webp";

export const texts = {
  en: {
    navbar: {
      home: "Welcome",
      skills: {
        main: "Skills",
        dropAgil: "Agile",
        dropTech: "Technical",
      },
      role: "Role",
      project: {
        main: "Projects",
        persoProj: "Personal",
        proProj: "Professional",
      },
      journey: "Journey",
      contact: { main: "Contact", resume: "Résumé" },
    },
    minimap: {
      backgroundHover: "Minimap",
      hover1: "Welcome",
      hover2: "Navigation",
      hover3: "Presentation",
      hover4: "Skills",
      hover5: "Scrum Master",
      hover6: "Philosophy",
      hover7: "Developper",
      hover8: "Technology",
      hover9: "Stances",
      hover10: "Roles",
      hover11: "Pro Projects",
      hover12: "Perso Project",
      hover13: "Miscellaneous",
      hover14: "Certifications",
      hover15: "Diplomas",
      hover16: "Contact",
      hover17: "Résumé",
    },
    characters: {
      //Intro
      character1: [
        {
          type: "title",
          content: "Welcome to the universe of the Scrum Nomad!",
          highlighted: [{ text: "Scrum Nomad", style: "highlight-slide" }],
        },
        {
          type: "subtitle",
          content: "I am IntRobot-1, here to guide you through this website.",
          highlighted: [{ text: "IntRobot-1", style: "highlight-italic" }],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad is my creator and the creator of this virtual space, a passionate Scrum Master from France, now living in New York.",
              highlighted: [
                { text: "Scrum Nomad", style: "highlight-slide" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "France", style: "highlight-bold" },
                { text: "New york", style: "highlight-bold" },
              ],
            },
            {
              text: "He has a unique background that includes advanced scientific studies, front-end development, and of course agility.",
              highlighted: [
                { text: "scientific studies", style: "highlight-bold" },
                { text: "front-end", style: "highlight-bold" },
                { text: "agility", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Currently, the Scrum Nomad is not available. His work authorization will be granted at the end of August 2024.",
              highlighted: [
                {
                  text: "Scrum Nomad",
                  style: "highlight-slide",
                },
                {
                  text: "not available",
                  style: "highlight-bold",
                },
                {
                  text: "the end of August 2024",
                  style: "highlight-bold",
                },
              ],
            },
            {
              text: "In the meantime, he is traveling through agile galaxies and exploring unknown dimensions to share his chronicles and convey his discoveries in agility.",
              highlighted: [
                {
                  text: "his discoveries",
                  style: "highlight-bold",
                },
                {
                  text: "in agility",
                  style: "highlight-bold",
                },
              ],
            },

            {
              text: "This site was designed to share his skills, journey, and vision of agility with you.",
              highlighted: [
                {
                  text: "vision of agility",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Each section you explore is a window into his achievements, projects, and work philosophy.",
              highlighted: [],
            },
            {
              text: "Whether you are looking for a technical Scrum Master or are curious about his journey, you are in the right place.",
              highlighted: [
                {
                  text: "Technical Scrum Master",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
      ],
      character2: [
        {
          type: "subtitle",
          content: "How to navigate this website?",
          highlighted: [{ text: "navigate", style: "highlight-bold" }],
        },
        {
          type: "paragraph",
          content:
            "This site is designed as an interactive exploration of the Scrum Nomad's skills and experiences.",
          highlighted: [{ text: "Scrum Nomad's", style: "highlight-slide" }],
        },
        {
          type: "list",
          content: [
            "Interactive Sections: Each section of the site is represented by a unique robot and color. Click on the robots to read its section.",
            "Minimap: Use the minimap located on the right side to navigate between different sections.",
            "Background Music: Ambient music starts at the introduction. If it bothers you, you can turn it off!",
            "Languages: The site is available in both French and English. You can change the language at any time.",
          ],
          highlighted: [
            { text: "Interactive sections:", style: "highlight-underline" },
            { text: "Minimap:", style: "highlight-underline" },
            { text: "Background Music:", style: "highlight-underline" },
            { text: "Languages:", style: "highlight-underline" },
            { text: "unique robot", style: "highlight-italic" },
            { text: "color", style: "highlight-italic" },
            { text: "the right side", style: "highlight-italic" },
            { text: "French", style: "highlight-italic" },
            { text: "English", style: "highlight-italic" },
          ],
        },

        {
          type: "paragraph",
          content:
            "To continue navigating, scroll down and interact with the different robots.",
          highlighted: [
            { text: "navigating", style: "highlight-italic" },
            { text: "robots", style: "highlight-bold" },
          ],
        },
      ],
      character3: [
        {
          type: "subtitle",
          content: "But you might be wondering, who is the Scrum Nomad?",
          highlighted: [
            { text: "who", style: "highlight-italic" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad is an agility enthusiast from France, who has been living in New York for the past two years.",
              highlighted: [
                { text: "agility enthusiast", style: "highlight-bold" },
                { text: "France", style: "highlight-italic" },
                { text: "New York", style: "highlight-italic" },
              ],
            },
            {
              text: "His journey is unique: after studying geology, he switched to web development and eventually became a Scrum Master.",
              highlighted: [
                { text: "unique", style: "highlight-bold" },
                { text: "geology", style: "highlight-italic" },
                { text: "web development", style: "highlight-italic" },
                { text: "Scrum Master", style: "highlight-bold" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "While waiting for his work authorization in the USA, he created this website. Why?",
              highlighted: [{ text: "Why?", style: "highlight-italic" }],
            },
            {
              text: "Simply because he loves ambitious projects and wanted to challenge himself by creating his own website from scratch.",
              highlighted: [
                { text: "ambitious projects", style: "highlight-bold" },
                { text: "challenge", style: "highlight-bold" },
                { text: "from scratch", style: "highlight-bold" },
              ],
            },
            {
              text: "He wanted to create an interactive business card.",
              highlighted: [
                { text: "visibility", style: "highlight-italic" },
                { text: "business card", style: "highlight-bold" },
              ],
            },
            {
              text: "This website serves both as his portfolio and as a platform to share his vision of Scrum.",
              highlighted: [
                {
                  text: "his vision of Scrum",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
      ],
      character4: [
        {
          type: "subtitle",
          content: "A Technical Scrum Master?",
          highlighted: [
            {
              text: "Technical Scrum Master",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Developing this site allowed him to learn technologies like React and GSAP.",
              highlighted: [
                { text: "learn", style: "highlight-italic" },
                { text: "React", style: "highlight-bold" },
                { text: "GSAP", style: "highlight-bold" },
              ],
            },
            {
              text: "He has always adhered to good development practices, overcoming numerous technical challenges.",
              highlighted: [
                { text: "development practices", style: "highlight-bold" },
                { text: "technical challenges", style: "highlight-bold" },
              ],
            },
            {
              text: "This helped him master various aspects of a project, from architecture to front-end.",
              highlighted: [
                { text: "architecture", style: "highlight-bold" },
                { text: "front-end", style: "highlight-bold" },
              ],
            },
            {
              text: "He believes it is essential for a Technical Scrum Master to have a thorough understanding of the technologies involved in their project.",
              highlighted: [
                {
                  text: "Technical Scrum Master",
                  style: "highlight-bold",
                },
                {
                  text: "technologies involved",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "The goal of this website? To showcase his skills and share his vision of agility in a fun and original way.",
          highlighted: [
            { text: "The goal of this website?", style: "highlight-underline" },
            { text: "fun and original way", style: "highlight-italic" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Ready to learn more? Check out the Skill-team section below to see how he can add value to your team and projects.",
          highlighted: [
            { text: "Skill-team", style: "highlight-italic" },
            { text: "add value", style: "highlight-bold" },
            { text: "your team", style: "highlight-bold" },
            { text: "projects", style: "highlight-bold" },
          ],
        },
      ],
      //agility skills
      character5: [
        {
          type: "title",
          content:
            "The Skill-Team is here! Let's talk about the Scrum Nomad's skills.",
          highlighted: [
            { text: "Skill-team", style: "highlight-bold" },
            { text: "skills", style: "highlight-bold" },
            { text: "Scrum Nomad's", style: "highlight-slide" },
          ],
        },
        {
          type: "subtitle",
          content:
            "With the following robots, we represent the skill-team section. We are always highly meticulous about the talents and skills of the Scrum Nomad.",
          highlighted: [
            { text: "skill-team", style: "highlight-bold" },
            { text: "talents", style: "highlight-bold" },
            { text: "skills", style: "highlight-bold" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "paragraph",
          content:
            "We will discuss agile skills with the robots with turquoise text, followed by his technical skills in orange.",
          highlighted: [
            { text: "agile skills", style: "highlight-bold" },
            { text: "turquoise", style: "highlight-turquoise" },
            { text: "skills", style: "highlight-bold" },
            { text: "technical", style: "highlight-bold" },
            { text: "orange", style: "highlight-orange" },
          ],
        },
      ],
      character6: [
        {
          type: "title",
          content: "An Agile Scrum Nomad?",
          highlighted: [
            { text: "Scrum Nomad", style: "highlight-slide" },
            { text: "Agile", style: "highlight-bold" },
          ],
        },
        {
          type: "subtitle",
          content:
            "First, let's dive into agility and the Scrum Nomad's agile philosophy.",
          highlighted: [
            { text: "agility", style: "highlight-bold" },
            { text: "philosophy", style: "highlight-bold" },
            { text: "agile", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad has taken on several key roles within agile teams, including that of a Technical Scrum Master and Scrum Coach.",
              highlighted: [
                { text: "key roles", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Technical", style: "highlight-bold" },
                { text: "Scrum Coach", style: "highlight-bold" },
              ],
            },
            {
              text: "He has developed expertise in facilitating Scrum ceremonies, adapting to the needs of each project.",
              highlighted: [
                { text: "Scrum ceremonies", style: "highlight-italic" },
                { text: "needs", style: "highlight-bold" },
                { text: "each project", style: "highlight-bold" },
              ],
            },
            {
              text: "He excels in facilitating sprint retrospectives, helping the team refocus on its goals, fostering constructive conflicts, and identifying areas for improvement.",
              highlighted: [
                { text: "sprint retrospectives", style: "highlight-italic" },
                { text: "refocus", style: "highlight-bold" },
                { text: "conflicts", style: "highlight-bold" },
                { text: "constructive", style: "highlight-bold" },
                { text: "improvement", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "A young agile team doesn't always fully grasp the empirical nature of the Scrum framework. That's why he takes on the role of mentor and coach to strengthen the culture of inspection and adaptation.",
          highlighted: [
            { text: "empirical", style: "highlight-bold" },
            { text: "mentor", style: "highlight-bold" },
            { text: "Scrum framework", style: "highlight-bold" },
            { text: "coach", style: "highlight-bold" },
            { text: "inspection", style: "highlight-italic" },
            { text: "adaptation", style: "highlight-italic" },
          ],
        },
      ],
      character7: [
        {
          type: "subtitle",
          content: "But what are the key pillars of his agility?",
          highlighted: [
            { text: "key pillars", style: "highlight-bold" },
            { text: "his", style: "highlight-italic" },
            { text: "agility", style: "highlight-bold" },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Challenging his skills, role, and understanding of problems, while emphasizing transparency.",
              highlighted: [
                {
                  text: "Challenging his skills",
                  style: "highlight-underline",
                },
                {
                  text: "transparency",
                  style: "highlight-italic",
                },
              ],
              imageSrc: discipline,
              imageSrcWebp: disciplineWebp,
              imageAlt: "discipline",
              highlightText: "Discipline",
              highlightPosition: { top: "-8vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Respecting the Scrum values (Courage, Focus, Commitment, Respect, and Openness) to foster an agile culture.",
              highlighted: [
                {
                  text: "Respecting the Scrum values",
                  style: "highlight-underline",
                },
                {
                  text: "(Courage",
                  style: "highlight-italic",
                },
                {
                  text: "Focus",
                  style: "highlight-italic",
                },
                {
                  text: "Commitment",
                  style: "highlight-italic",
                },
                {
                  text: "Respect",
                  style: "highlight-italic",
                },
                {
                  text: "Openness)",
                  style: "highlight-italic",
                },
                {
                  text: "agile culture",
                  style: "highlight-bold",
                },
              ],
              imageSrc: behavior,
              imageSrcWebp: behaviorWebp,
              imageAlt: "behavior",
              highlightText: "Behavior",
              highlightPosition: { top: "1vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Working on product value, ensuring that the Scrum team and stakeholders fully understand the impact of Product Backlog Items (PBIs).",
              highlighted: [
                {
                  text: "Working on product value",
                  style: "highlight-underline",
                },
                {
                  text: "Product Backlog",
                  style: "highlight-italic",
                },
                {
                  text: "Items",
                  style: "highlight-italic",
                },
                {
                  text: "PBIs",
                  style: "highlight-bold",
                },
              ],
              imageSrc: clientValue,
              imageSrcWebp: clientValueWebp,
              imageAlt: "clientValue",
              highlightText: "Value",
              highlightPosition: { top: "10vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Fostering collaboration within the Scrum team, enabling the development of new skills through shared experiences and collective learning.",
              highlighted: [
                {
                  text: "Fostering collaboration",
                  style: "highlight-underline",
                },
                {
                  text: "new skills",
                  style: "highlight-bold",
                },
                {
                  text: "shared experiences",
                  style: "highlight-bold",
                },
                {
                  text: "collective learning",
                  style: "highlight-bold",
                },
              ],
              imageSrc: helpingTeam,
              imageSrcWebp: helpingTeamWebp,
              imageAlt: "helpingTeam",
              highlightText: "Helping Team",
              highlightPosition: { top: "19vw", left: "40vw" },
              textPosition: "right",
            },
          ],
        },
      ],
      character8: [
        {
          type: "subtitle",
          content: "His agile philosophy, a mindset.",
          highlighted: [
            { text: "mindset", style: "highlight-bold" },
            { text: "philosophy", style: "highlight-bold" },
            { text: "agile", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad's agile philosophy is rooted in enhancing teamwork and processes.",
              highlighted: [
                { text: "enhancing", style: "highlight-italic" },
                { text: "teamwork", style: "highlight-bold" },
                { text: "processes", style: "highlight-bold" },
              ],
            },
            {
              text: "Scrum offers many ways to maximize efficiency. He embodies the role of Servant Leader to enhance team performance.",
              highlighted: [
                { text: "maximize efficiency", style: "highlight-bold" },
                { text: "Servant Leader", style: "highlight-italic" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "The essential soft-skills of the Scrum Nomad within a Scrum team include:",
          highlighted: [{ text: "soft-skills", style: "highlight-bold" }],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Teaching: The Scrum Nomad assists team members in thoroughly understanding the Scrum framework, emphasizing its values and principles.",
              highlighted: [
                {
                  text: "Teaching:",
                  style: "highlight-underline",
                },
                {
                  text: "Scrum Nomad",
                  style: "highlight-slide",
                },
                {
                  text: "Scrum framework",
                  style: "highlight-bold",
                },
                {
                  text: "values",
                  style: "highlight-bold",
                },
                {
                  text: "principles",
                  style: "highlight-bold",
                },
              ],
              imageSrc: teaching,
              imageSrcWebp: teachingWebp,
              imageAlt: "teaching",
              highlightText: "Teaching Skills",
              highlightPosition: { top: "-6vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Facilitation: He guides teams in finding their own solutions and making the best decisions, thereby fostering positive collaboration.",
              highlighted: [
                {
                  text: "Facilitation:",
                  style: "highlight-underline",
                },
                {
                  text: "own solutions",
                  style: "highlight-bold",
                },
                {
                  text: "best decisions",
                  style: "highlight-bold",
                },
                {
                  text: "collaboration",
                  style: "highlight-bold",
                },
                {
                  text: "positive",
                  style: "highlight-bold",
                },
              ],
              imageSrc: facilitation,
              imageSrcWebp: facilitationWebp,
              imageAlt: "facilitation",
              highlightText: "Facilitator",
              highlightPosition: { top: "0vw", left: "55vw" },
              textPosition: "left",
            },
            {
              content:
                "Coaching: Enhancing the team's ability to learn and achieve its goals by encouraging each member to take ownership of their decisions.",
              highlighted: [
                {
                  text: "Coaching:",
                  style: "highlight-underline",
                },
                {
                  text: "learn",
                  style: "highlight-bold",
                },
                {
                  text: "ownership",
                  style: "highlight-bold",
                },
                {
                  text: "decisions",
                  style: "highlight-bold",
                },
              ],
              imageSrc: coaching,
              imageSrcWebp: coachingWebp,
              imageAlt: "coaching",
              highlightText: "Coaching skills",
              highlightPosition: { top: "6vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Developer: Adjusted to the specific technologies and constraints of each project. It's essential to consider the rapid evolution of techniques, especially in the age of AI.",
              highlighted: [
                {
                  text: "Developer:",
                  style: "highlight-underline",
                },
                {
                  text: "technologies",
                  style: "highlight-bold",
                },
                {
                  text: "constraints",
                  style: "highlight-bold",
                },
                {
                  text: "rapid evolution",
                  style: "highlight-bold",
                },
                {
                  text: "age of AI",
                  style: "highlight-italic",
                },
              ],
              imageSrc: technicalExcellence,
              imageSrcWebp: technicalExcellenceWebp,
              imageAlt: "helpingTeam",
              highlightText: "Technical Excellence",
              highlightPosition: { top: "13vw", left: "55vw" },
              textPosition: "left",
            },
            {
              content:
                "Servant Leader: The Scrum Nomad supports the Product Owner, the development team, and the organization by helping each to grow their capabilities. His success is measured by the success of the entire team.",
              highlighted: [
                {
                  text: "Servant Leader:",
                  style: "highlight-underline",
                },
                {
                  text: "Product Owner",
                  style: "highlight-italic",
                },
                {
                  text: "development team",
                  style: "highlight-italic",
                },
                {
                  text: "organization",
                  style: "highlight-italic",
                },
                {
                  text: "success",
                  style: "highlight-bold",
                },
                {
                  text: "entire team",
                  style: "highlight-bold",
                },
              ],
              imageSrc: serventLeader,
              imageSrcWebp: serventLeaderWebp,
              imageAlt: "serventLeader",
              highlightText: "Servant leadership",
              highlightPosition: { top: "20vw", left: "40vw" },
              textPosition: "right",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "The Scrum Nomad firmly believes that continuous improvement is driven by the self-organization of teams, using retrospectives and daily meetings to identify impediments and remove obstacles.",
          highlighted: [
            { text: "continuous improvement", style: "highlight-italic" },
            { text: "self-organization", style: "highlight-italic" },
            { text: "impediments", style: "highlight-bold" },
            { text: "obstacles", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraph",
          content:
            "He fosters collaboration by promoting affinities and knowledge sharing, ensuring a cross-functional team that thrives through cooperation.",
          highlighted: [
            {
              text: "collaboration",
              style: "highlight-bold",
            },
            {
              text: "affinities",
              style: "highlight-bold",
            },
            {
              text: "knowledge sharing",
              style: "highlight-bold",
            },
            {
              text: "cross-functional",
              style: "highlight-italic",
            },
            {
              text: "cooperation",
              style: "highlight-bold",
            },
          ],
        },
      ],
      character9: [
        {
          type: "subtitle",
          content: "Challenges and Learning",
          highlighted: [
            {
              text: "Challenges",
              style: "highlight-underline",
            },
            {
              text: "Learning",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Managing conflicts within agile teams is part of the Scrum Nomad's role.",
              highlighted: [
                { text: "Managing conflicts", style: "highlight-bold" },
              ],
            },
            {
              text: "He seeks to transform differing viewpoints into new ideas, fostering constructive debates to create tangible solutions.",
              highlighted: [
                { text: "transform", style: "highlight-bold" },
                { text: "new ideas", style: "highlight-bold" },
                { text: "constructive debates", style: "highlight-bold" },
                { text: "tangible solutions", style: "highlight-bold" },
              ],
            },
            {
              text: "He is vigilant in identifying and resolving obstacles as they arise, preventing them from slowing down the process.",
              highlighted: [
                { text: "identifying", style: "highlight-bold" },
                { text: "resolving", style: "highlight-bold" },
              ],
            },
            {
              text: "Thanks to the Scrum Values, he has successfully guided reluctant colleagues toward adopting more effective and agile working methods.",
              highlighted: [
                { text: "Scrum Values", style: "highlight-italic" },
                { text: "guided", style: "highlight-bold" },
                { text: "adopting", style: "highlight-bold" },
                { text: "effective", style: "highlight-bold" },
                { text: "agile", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subtitle",
          content: "Future Growth and Development",
          highlighted: [
            {
              text: "Future Growth",
              style: "highlight-underline",
            },
            {
              text: "Development",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "As a Professional Scrum Master, the Scrum Nomad aims to continuously apply and refine his skills and knowledge in agility.",
              highlighted: [
                {
                  text: "Professional Scrum Master",
                  style: "highlight-italic",
                },
                { text: "skills", style: "highlight-bold" },
                { text: "knowledge", style: "highlight-bold" },
              ],
            },
            {
              text: "He plans to compose a conference on agility, with a particular focus on Scrum, and to specialize in Scrum consulting and coaching.",
              highlighted: [
                { text: "conference on agility", style: "highlight-bold" },
                { text: "Scrum", style: "highlight-italic" },
                { text: "consulting", style: "highlight-bold" },
                { text: "coaching", style: "highlight-bold" },
              ],
            },
            {
              text: "His goal is to maximize the benefits of Scrum by promoting the agile mindset, and processes.",
              highlighted: [
                { text: "benefits of Scrum", style: "highlight-bold" },
                { text: "mindset", style: "highlight-italic" },
                { text: "processes.", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      //technical skills
      character10: [
        {
          type: "title",
          content: "The Scrum Nomad, a developer?",
          highlighted: [
            {
              text: "developer",
              style: "highlight-italic2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Yes, a front-end developer.",
          highlighted: [
            {
              text: "Yes",
              style: "highlight-underline2",
            },
            {
              text: "front-end",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Hello, explorer! I'm TechBot from the Skill-team, and we're going to focus on the Scrum Nomad's technical skills here.",
          highlighted: [
            {
              text: "TechBot",
              style: "highlight-italic2",
            },
            {
              text: "technical skills",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad developed this website using React, GSAP, CSS, HTML5, and JavaScript.",
              highlighted: [
                { text: "React", style: "highlight-bold2" },
                { text: "GSAP", style: "highlight-bold2" },
                { text: "CSS", style: "highlight-bold2" },
                { text: "HTML5", style: "highlight-bold2" },
                { text: "JavaScript", style: "highlight-bold2" },
              ],
            },
            {
              text: "Professionally, he has primarily worked with Angular and JavaScript, as well as PHP, and has knowledge of Java.",
              highlighted: [
                { text: "Angular", style: "highlight-bold2" },
                { text: "JavaScript", style: "highlight-bold2" },
                { text: "PHP", style: "highlight-bold2" },
                { text: "Java", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad values front-end development for its direct impact on the user experience.",
              highlighted: [
                { text: "front-end", style: "highlight-bold2" },
                { text: "user", style: "highlight-bold2" },
                { text: "experience", style: "highlight-bold2" },
              ],
            },
            {
              text: "He emphasizes good development practices, with a particular focus on performance and an eco-friendly approach through green-coding.",
              highlighted: [
                { text: "performance", style: "highlight-bold2" },
                { text: "green-coding", style: "highlight-bold2" },
              ],
            },
          ],
        },
      ],
      character11: [
        {
          type: "subtitle",
          content: "What technologies are used?",
          highlighted: [
            {
              text: "technologies",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "To enhance his projects, the Scrum Nomad employs several technologies:",
          highlighted: [
            {
              text: "enhance",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "He uses GSAP to create smooth animations and dynamic visual effects, ensuring high performance while enhancing the visual appeal and interactivity of the interfaces.",
              highlighted: [
                {
                  text: "GSAP",
                  style: "highlight-underline2",
                },
                {
                  text: "smooth animations",
                  style: "highlight-bold2",
                },
                {
                  text: "performance",
                  style: "highlight-bold2",
                },
                {
                  text: "interactivity",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: gsap,
              imageSrcWebp: gsapWebp,
              imageAlt: "gsap",
              highlightText: "GreenSock",
              highlightPosition: { top: "-9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "With CSS and HTML5, he focuses on using flexbox to create clean and dynamic interfaces, maintaining a modular codebase through the use of global variables.",
              highlighted: [
                {
                  text: "CSS and HTML5",
                  style: "highlight-underline2",
                },
                {
                  text: "flexbox",
                  style: "highlight-bold2",
                },
                {
                  text: "clean",
                  style: "highlight-bold2",
                },
                {
                  text: "dynamic",
                  style: "highlight-bold2",
                },
                {
                  text: "codebase",
                  style: "highlight-bold2",
                },
                {
                  text: "modular",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: htmlcss,
              imageSrcWebp: htmlcssWebp,
              imageAlt: "htmlcss",
              highlightText: "CSS/HTML5",
              highlightPosition: { top: "-5vw", left: "52vw" },
              textPosition: "left",
            },
            {
              content:
                "He is familiar with the Angular framework for its solid structure, handling of observables, and robust projects. He also knows React, utilizing its hooks to optimize the maintenance of his applications.",
              highlighted: [
                {
                  text: "Angular framework",
                  style: "highlight-underline2",
                },
                {
                  text: "observables",
                  style: "highlight-bold2",
                },
                {
                  text: "React,",
                  style: "highlight-underline2",
                },
                {
                  text: "hooks",
                  style: "highlight-bold2",
                },
                {
                  text: "maintenance",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: reactangular,
              imageSrcWebp: reactangularWebp,
              imageAlt: "reactangular",
              highlightText: "Angular/React",
              highlightPosition: { top: "0vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "When necessary, he creates custom JavaScript code to meet project needs, ensuring a precise, smooth, and interactive user experience.",
              highlighted: [
                {
                  text: "JavaScript code",
                  style: "highlight-underline2",
                },
                {
                  text: "needs",
                  style: "highlight-bold2",
                },
                {
                  text: "precise",
                  style: "highlight-bold2",
                },
                {
                  text: "smooth",
                  style: "highlight-bold2",
                },
                {
                  text: "interactive",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: js,
              imageSrcWebp: jsWebp,
              imageAlt: "javaScript",
              highlightText: "JavaScript",
              highlightPosition: { top: "4vw", left: "53vw" },
              textPosition: "left",
            },
            {
              content:
                "He also has backend development skills and solid experience in database management using SQL.",
              highlighted: [
                {
                  text: "backend ",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: sql,
              imageSrcWebp: sqlWebp,
              imageAlt: "sql",
              highlightText: "SQL/Backend",
              highlightPosition: { top: "9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "He is proficient in Git and emphasizes a well-organized commit and branch architecture for efficient development.",
              highlighted: [
                {
                  text: "Git",
                  style: "highlight-bold2",
                },
                {
                  text: "architecture",
                  style: "highlight-bold2",
                },
                {
                  text: "commit",
                  style: "highlight-italic2",
                },
                {
                  text: "branch",
                  style: "highlight-italic2",
                },
                {
                  text: "well-organized",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: git,
              imageSrcWebp: gitWebp,
              imageAlt: "git",
              highlightText: "Version Management",
              highlightPosition: { top: "15vw", left: "55vw" },
              textPosition: "left",
            },
          ],
        },
      ],
      character12: [
        {
          type: "subtitle",
          content: "Evolution and Learning",
          highlighted: [
            {
              text: "Evolution",
              style: "highlight-underline2",
            },
            {
              text: "Learning",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Keeping up with new technologies and trends is a challenge that the Scrum Nomad constantly meets.",
              highlighted: [
                { text: "new", style: "highlight-bold2" },
                { text: "technologies", style: "highlight-bold2" },
                { text: "challenge", style: "highlight-bold2" },
                { text: "meets", style: "highlight-bold2" },
                { text: "constantly", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Building this site from scratch allowed him to learn new technologies and frameworks.",
              highlighted: [
                { text: "from scratch", style: "highlight-italic2" },
                { text: "technologies", style: "highlight-bold2" },
                { text: "frameworks", style: "highlight-bold2" },
              ],
            },
            {
              text: "He is always ready to explore new, relevant solutions for the projects he works on.",
              highlighted: [
                { text: "new", style: "highlight-bold2" },
                { text: "relevant solutions", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "subtitle",
          content: "Technical DoD and DoR",
          highlighted: [
            {
              text: "DoD",
              style: "highlight-italic2",
            },
            {
              text: "DoR",
              style: "highlight-italic2",
            },
            {
              text: "Technical",
              style: "highlight-underline2",
            },
          ],
        },

        {
          type: "paragraph",
          content:
            "As a Technical Scrum Master, he emphasizes defining DoD (Definition of Done) and DoR (Definition of Ready) with technical criteria to ensure best practices and optimal code quality.",
          highlighted: [
            {
              text: "Technical Scrum Master",
              style: "highlight-italic2",
            },
            {
              text: "DoD",
              style: "highlight-bold2",
            },

            {
              text: "DoR",
              style: "highlight-bold2",
            },
            {
              text: "technical criteria",
              style: "highlight-italic2",
            },
            {
              text: "practices",
              style: "highlight-italic2",
            },
            {
              text: "optimal",
              style: "highlight-italic2",
            },
          ],
        },
      ],
      //Role
      character13: [
        {
          type: "title",
          content: "The Roles of the Scrum Nomad",
          highlighted: [
            { text: "Roles", style: "highlight-underline" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "subtitle",
          content: "The Stances of a Technical Scrum Master",
          highlighted: [
            {
              text: "Stances",
              style: "highlight-bold",
            },
            {
              text: "Technical Scrum Master",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The initial role of the Scrum Nomad, given his various skills, is Technical Scrum Master.",
              highlighted: [
                { text: "initial role", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Technical", style: "highlight-bold" },
              ],
            },
            {
              text: "He brings significant value to a development team through his agile and technical skills.",
              highlighted: [
                { text: "significant value", style: "highlight-bold" },
                { text: "skills", style: "highlight-bold" },
                { text: "agile", style: "highlight-bold" },
                { text: "technical", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Here's how his role as a Technical Scrum Master translates into the management and success of your projects:",
          highlighted: [
            { text: "Technical Scrum Master", style: "highlight-bold" },
            {
              text: "management",
              style: "highlight-bold",
            },
            {
              text: "success",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Understanding Challenges: With his development skills, he accurately grasps the team's issues, enabling better communication and problem-solving.",
              highlighted: [
                {
                  text: "Understanding Challenges:",
                  style: "highlight-underline",
                },
                {
                  text: "team's issues",
                  style: "highlight-bold",
                },
                {
                  text: "better",
                  style: "highlight-bold",
                },
                {
                  text: "communication",
                  style: "highlight-bold",
                },
                {
                  text: "problem-solving",
                  style: "highlight-bold",
                },
              ],
              imageSrc: understandingTech,
              imageSrcWebp: understandingTechWebp,
              imageAlt: "understandingTech",
              highlightText: "Technical Understanding",
              highlightPosition: { top: "-14vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Problem Facilitation: His technical expertise allows for swift solutions to encountered obstacles, thus facilitating project progress.",
              highlighted: [
                {
                  text: "Problem Facilitation:",
                  style: "highlight-underline",
                },
                {
                  text: "solutions",
                  style: "highlight-bold",
                },
                {
                  text: "facilitating",
                  style: "highlight-italic",
                },
                {
                  text: "progress",
                  style: "highlight-italic",
                },
              ],
              imageSrc: facilitationResolution,
              imageSrcWebp: facilitationResolutionWebp,
              imageAlt: "facilitationResolution",
              highlightText: "Problem Facilitation",
              highlightPosition: { top: "-8vw", left: "57vw" },
              textPosition: "left",
            },
            {
              content:
                "Improved PBI: The Scrum Nomad helps provide more accurate effort estimates, ensuring reliable planning.",
              highlighted: [
                {
                  text: "Improved PBI:",
                  style: "highlight-underline",
                },
                {
                  text: "helps",
                  style: "highlight-italic",
                },
                {
                  text: "estimates",
                  style: "highlight-bold",
                },
                {
                  text: "reliable planning",
                  style: "highlight-italic",
                },
              ],
              imageSrc: effortEstimation,
              imageSrcWebp: effortEstimationWebp,
              imageAlt: "effortEstimation",
              highlightText: "Enhanced Estimation",
              highlightPosition: { top: "-2vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Technical Mentorship: He guides team members in adopting best development practices, enhancing code quality and team efficiency.",
              highlighted: [
                {
                  text: "Technical Mentorship:",
                  style: "highlight-underline",
                },
                {
                  text: "guides",
                  style: "highlight-italic",
                },
                {
                  text: "practices",
                  style: "highlight-italic",
                },
                {
                  text: "code quality",
                  style: "highlight-bold",
                },
                {
                  text: "team efficiency",
                  style: "highlight-bold",
                },
              ],
              imageSrc: technicalCoaching,
              imageSrcWebp: technicalCoachingWebp,
              imageAlt: "technicalCoaching",
              highlightText: "Technical Coaching",
              highlightPosition: { top: "4vw", left: "58vw" },
              textPosition: "left",
            },
            {
              content:
                "Project mediation: By understanding technical requirements and business objectives, he plays a crucial role in mediating and facilitating discussions.",
              highlighted: [
                {
                  text: "Project mediation:",
                  style: "highlight-underline",
                },
                {
                  text: "requirements",
                  style: "highlight-bold",
                },
                {
                  text: "objectives",
                  style: "highlight-bold",
                },
                {
                  text: "mediating",
                  style: "highlight-italic",
                },
                {
                  text: "facilitating",
                  style: "highlight-italic",
                },
              ],
              imageSrc: mediation,
              imageSrcWebp: mediationWebp,
              imageAlt: "mediation",
              highlightText: "Mediation",
              highlightPosition: { top: "9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Continuous improvement: He identifies opportunities for improvement, encourages refactoring when necessary, and rapidly integrates new technologies to optimize working methods.",
              highlighted: [
                {
                  text: "Continuous improvement:",
                  style: "highlight-underline",
                },
                {
                  text: "opportunities",
                  style: "highlight-bold",
                },
                {
                  text: "refactoring",
                  style: "highlight-italic",
                },
                {
                  text: "new",
                  style: "highlight-bold",
                },
                {
                  text: "technologies",
                  style: "highlight-bold",
                },
              ],
              imageSrc: continuousImprovement,
              imageSrcWebp: continuousImprovementWebp,
              imageAlt: "continuousImprovement",
              highlightText: "Continuous improvement",
              highlightPosition: { top: "14vw", left: "56vw" },
              textPosition: "left",
            },
            {
              content:
                "Better obstacle management: technical expertise enables us to quickly identify and overcome technical obstacles, minimizing delays and interruptions.",
              highlighted: [
                {
                  text: "Better obstacle management:",
                  style: "highlight-underline",
                },
                {
                  text: "identify",
                  style: "highlight-bold",
                },
                {
                  text: "minimizing delays",
                  style: "highlight-bold",
                },
                {
                  text: "interruptions",
                  style: "highlight-bold",
                },
              ],
              imageSrc: obstacleManagement,
              imageSrcWebp: obstacleManagementWebp,
              imageAlt: "obstacleManagement",
              highlightText: "Obstacle Management",
              highlightPosition: { top: "20vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Trust and credibility: As Technical Scrum Master, he inspires trust and credibility within the team, fostering a collaborative and productive working environment.",
              highlighted: [
                {
                  text: "Trust and credibility:",
                  style: "highlight-underline",
                },
                {
                  text: "collaborative",
                  style: "highlight-italic",
                },
                {
                  text: "productive",
                  style: "highlight-italic",
                },
              ],
              imageSrc: trustCredibility,
              imageSrcWebp: trustCredibilityWebp,
              imageAlt: "trustCredibility",
              highlightText: "Trust",
              highlightPosition: { top: "25vw", left: "58vw" },
              textPosition: "left",
            },
          ],
        },
      ],
      character14: [
        {
          type: "subtitle",
          content: "What roles does it play in your projects?",
          highlighted: [
            {
              text: "What roles",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The different stances of the Scrum Nomad enable you to play the role of servant leader effectively.",
              highlighted: [
                { text: "stances", style: "highlight-bold" },
                { text: "servant leader", style: "highlight-italic" },
              ],
            },
            {
              text: "He guides his team towards excellence, maximizing the value delivered to customers and stakeholders.",
              highlighted: [
                { text: "guides", style: "highlight-italic" },
                { text: "excellence", style: "highlight-bold" },
                { text: "maximizing", style: "highlight-bold" },
                { text: "value", style: "highlight-bold" },
              ],
            },

            {
              text: "With these skills and attitudes, he is able to adapt to situations and company needs, tailoring his approach on a case-by-case basis.",
              highlighted: [
                { text: "adapt", style: "highlight-bold" },
                { text: "needs,", style: "highlight-bold" },
                { text: "case-by-case", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "But in concrete terms, being a servant leader allows him to assume what roles?",
          highlighted: [
            {
              text: "concrete terms",
              style: "highlight-bold",
            },
            {
              text: "servent",
              style: "highlight-italic",
            },
            {
              text: "leader",
              style: "highlight-italic",
            },
            {
              text: "what roles?",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Technical Scrum Master: Bringing agile and technical understanding to improve development processes and problem solving.",
              highlighted: [
                {
                  text: "Technical Scrum Master:",
                  style: "highlight-underline",
                },
                {
                  text: "agile",
                  style: "highlight-bold",
                },
                {
                  text: "technical",
                  style: "highlight-bold",
                },
                {
                  text: "development processes",
                  style: "highlight-italic",
                },
                {
                  text: "problem solving",
                  style: "highlight-italic",
                },
              ],
              imageSrc: scrumMasterTech,
              imageSrcWebp: scrumMasterTechWebp,
              imageAlt: "scrumMasterTech",
              highlightText: "T.S.M.",
              highlightPosition: { top: "-7vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Agile Consultant: Provide strategic advice on the implementation and improvement of agile practices within the organization.",
              highlighted: [
                {
                  text: "Agile Consultant:",
                  style: "highlight-underline",
                },
                {
                  text: "strategic advice",
                  style: "highlight-bold",
                },
                {
                  text: "agile practices",
                  style: "highlight-bold",
                },
              ],
              imageSrc: agileAdvisor,
              imageSrcWebp: agileAdvisorWebp,
              imageAlt: "agileAdvisor",
              highlightText: "Consultant",
              highlightPosition: { top: "-2vw", left: "52vw" },
              textPosition: "left",
            },
            {
              content:
                "Scrum Trainer: Lead training sessions to train teams in Scrum methodologies and reinforce their skills.",
              highlighted: [
                {
                  text: "Scrum Trainer:",
                  style: "highlight-underline",
                },
                {
                  text: "training",
                  style: "highlight-bold",
                },
                {
                  text: "sessions",
                  style: "highlight-bold",
                },
                {
                  text: "Scrum methodologies",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumTrainer,
              imageSrcWebp: scrumTrainerWebp,
              imageAlt: "scrumTrainer",
              highlightText: "Trainer",
              highlightPosition: { top: "3vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Scrum Coach: Work closely with teams to adopt and improve Scrum practices, supporting their ongoing development.",
              highlighted: [
                {
                  text: "Scrum Coach:",
                  style: "highlight-underline",
                },
                {
                  text: "adopt",
                  style: "highlight-italic",
                },
                {
                  text: "improve",
                  style: "highlight-italic",
                },
                {
                  text: "Scrum practices",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumCoach,
              imageSrcWebp: scrumCoachWebp,
              imageAlt: "scrumCoach",
              highlightText: "Coach",
              highlightPosition: { top: "7.5vw", left: "56vw" },
              textPosition: "left",
            },
            {
              content:
                "Scrum Auditor: Assess the Scrum maturity of projects and propose recommendations to improve team efficiency and performance.",
              highlighted: [
                {
                  text: "Scrum Auditor:",
                  style: "highlight-underline",
                },
                {
                  text: "Scrum maturity",
                  style: "highlight-bold",
                },
                {
                  text: "recommendations",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumAuditor,
              imageSrcWebp: scrumAuditorWebp,
              imageAlt: "htmlcss",
              highlightText: "Auditor",
              highlightPosition: { top: "12vw", left: "39vw" },
              textPosition: "right",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "These multiple skills, roles and stances enable the Scrum Nomad to adapt to the specific needs of each organization, ensuring a flexible approach.",
          highlighted: [
            {
              text: "skills",
              style: "highlight-italic",
            },
            {
              text: "roles",
              style: "highlight-italic",
            },
            {
              text: "stances",
              style: "highlight-italic",
            },

            {
              text: "specific needs",
              style: "highlight-bold",
            },
            {
              text: "flexible",
              style: "highlight-bold",
            },
          ],
        },
      ],
      //Exp
      character15: [
        {
          type: "title",
          content: "Projects and Achievements",
          highlighted: [
            { text: "Projects", style: "highlight-underline" },
            { text: "Achievements", style: "highlight-underline" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Hi, I'm ProjectBot, and I'm in charge of presenting the projects and achievements of the Scrum Nomad. Here's an overview of his professional experiences and solutions.",
          highlighted: [
            {
              text: "ProjectBot",
              style: "highlight-italic",
            },
            {
              text: "projects",
              style: "highlight-bold",
            },
            {
              text: "achievements",
              style: "highlight-bold",
            },
            {
              text: "experiences",
              style: "highlight-bold",
            },
            {
              text: "professional",
              style: "highlight-bold",
            },
            {
              text: "solutions",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Professional Projects",
          highlighted: [
            {
              text: "Professional Projects",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad has held various key roles in complex development projects, including Lead Scrum Master, Scrum Master and Developer.",
              highlighted: [
                { text: "key roles", style: "highlight-bold" },
                { text: "projects", style: "highlight-italic" },
                { text: "development", style: "highlight-italic" },
                { text: "Lead Scrum Master", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Developer", style: "highlight-bold" },
              ],
            },
            {
              text: "His expertise helped transform teams and processes to achieve significant results.",
              highlighted: [
                { text: "transform", style: "highlight-bold" },
                { text: "results", style: "highlight-bold" },
                { text: "significant", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Project e-GMAO",
          highlighted: [
            { text: "Project e-GMAO", style: "highlight-subsubtile" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "As Lead Scrum Master, he adapted the operation of two joint teams and improved their maturity and autonomy.",
              highlighted: [
                { text: "Lead Scrum Master", style: "highlight-italic" },
                { text: "adapted", style: "highlight-italic" },
                { text: "two joint teams", style: "highlight-bold" },
                { text: "improved", style: "highlight-italic" },
                { text: "maturity", style: "highlight-bold" },
                { text: "autonomy", style: "highlight-bold" },
              ],
            },
            {
              text: "He has refactored Scrum processes for better communication and implemented project tools to increase clarity and efficiency.",
              highlighted: [
                { text: "refactored", style: "highlight-bold" },
                { text: "better", style: "highlight-bold" },
                { text: "communication", style: "highlight-bold" },
                { text: "clarity", style: "highlight-bold" },
                { text: "efficiency", style: "highlight-bold" },
              ],
            },
            {
              text: "He also implemented a transition to Kanban, which increased fluidity and better delivered value.",
              highlighted: [
                { text: "transition", style: "highlight-bold" },
                { text: "Kanban", style: "highlight-italic" },
                { text: "fluidity", style: "highlight-bold" },
                { text: "better delivered", style: "highlight-bold" },
                { text: "value", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Project CNM",
          highlighted: [{ text: "Project CNM", style: "highlight-subsubtile" }],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "He led the refactoring of an obsolete AngularJS application.",
              highlighted: [
                { text: "refactoring", style: "highlight-italic" },
                { text: "AngularJS", style: "highlight-bold" },
                { text: "obsolete", style: "highlight-bold" },
              ],
            },
            {
              text: "By overseeing the planning and delivery process, he met tight deadlines and coached a junior Product Owner to improve PBIs writing and prioritization.",
              highlighted: [
                { text: "planning", style: "highlight-bold" },
                { text: "delivery", style: "highlight-bold" },
                { text: "coached", style: "highlight-italic" },
                { text: "Product Owner", style: "highlight-italic" },
                { text: "PBIs writing", style: "highlight-bold" },
                { text: "prioritization", style: "highlight-bold" },
              ],
            },
            {
              text: "He also refocused the team's understanding of value, working on the actual value of PBIs.",
              highlighted: [
                { text: "refocused", style: "highlight-bold" },
                { text: "value", style: "highlight-bold" },
                { text: "PBIs", style: "highlight-italic" },
              ],
            },
            {
              text: "He clarified the concept of value, helping the development team better organize their solutions and assisting the PO in organizing the backlog more effectively.",
              highlighted: [
                { text: "clarified", style: "highlight-bold" },
                { text: "concept of", style: "highlight-bold" },
                { text: "value", style: "highlight-bold" },
                { text: "organizing the backlog", style: "highlight-bold" },
              ],
            },
            {
              text: "Thanks to these various actions, the team's velocity increased by 70%.",
              highlighted: [
                { text: "velocity", style: "highlight-bold" },
                { text: "increased", style: "highlight-bold" },
                { text: "70%", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Project REXEL",
          highlighted: [
            { text: "Project REXEL", style: "highlight-subsubtile" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "As a developer, he refactored an outdated PHP application by integrating the Symfony framework to improve maintainability and security.",
              highlighted: [
                { text: "developer", style: "highlight-bold" },
                { text: "refactored", style: "highlight-italic" },
                { text: "PHP application", style: "highlight-bold" },
                { text: "outdated", style: "highlight-bold" },
                { text: "Symfony framework", style: "highlight-italic" },
                { text: "maintainability", style: "highlight-bold" },
                { text: "security", style: "highlight-bold" },
              ],
            },
            {
              text: "He closely collaborated with the previous team for effective knowledge transfer and implemented new features while streamlining deployment processes.",
              highlighted: [
                { text: "collaborated", style: "highlight-bold" },
                { text: "knowledge transfer", style: "highlight-bold" },
                { text: "effective", style: "highlight-bold" },
                { text: "new features", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      character16: [
        {
          type: "subtitle",
          content: "Personal Project",
          highlighted: [
            {
              text: "Personal Project",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad's biggest personal project is this virtual world you are exploring.",
              highlighted: [
                { text: "biggest personal project", style: "highlight-bold" },
                { text: "virtual world", style: "highlight-bold" },
              ],
            },
            {
              text: "He conceptualized and built this website from scratch, handling everything from design and imagery to animation code, all by himself.",
              highlighted: [
                { text: "conceptualized", style: "highlight-bold" },
                { text: "built", style: "highlight-bold" },
                { text: "design", style: "highlight-bold" },
                { text: "imagery", style: "highlight-bold" },
                { text: "from scratch", style: "highlight-italic" },
                { text: "himself", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad's website was designed with a strong focus on performance and optimization. Here are some technical details:",
              highlighted: [
                { text: "performance", style: "highlight-bold" },
                { text: "optimization", style: "highlight-italic" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "Technologies used: React, GSAP, CSS, HTML5, JavaScript.",
              highlighted: [
                {
                  text: "Technologies used:",
                  style: "highlight-underline",
                },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "Design and Architecture: The site employs a modular architecture with reusable components, enabling easy maintenance and scalability.",
              highlighted: [
                {
                  text: "Design and Architecture:",
                  style: "highlight-underline",
                },
                { text: "modular", style: "highlight-italic" },
                { text: "reusable", style: "highlight-bold" },
                { text: "easy maintenance", style: "highlight-bold" },
                { text: "scalability", style: "highlight-bold" },
              ],
            },
            {
              text: "The design focuses on a smooth and intuitive navigation experience. The site's structure relies on an efficient state management system to synchronize various animations and user interactions.",
              highlighted: [
                { text: "smooth fluide", style: "highlight-bold" },
                { text: "intuitive", style: "highlight-bold" },
                { text: "navigation", style: "highlight-bold" },
                { text: "state management", style: "highlight-bold" },
              ],
            },
            {
              text: "All the images were generated and then modified by artificial intelligence. MidJourney for creative images, DALL·E for icons, and Photoshop AI for modifications.",
              highlighted: [
                { text: "artificial intelligence", style: "highlight-bold" },
                { text: "MidJourney", style: "highlight-bold" },
                { text: "DALL·E", style: "highlight-bold" },
                { text: "Photoshop AI", style: "highlight-bold" },
              ],
            },
            {
              text: "Each of these images was then individually refined and optimized according to the Scrum Nomad's needs to achieve the desired result..",
              highlighted: [
                { text: "individually", style: "highlight-bold" },
                { text: "refined", style: "highlight-bold" },
                { text: "optimized", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Performance Optimization: Implemented LazyLoading and prioritized sequential image loading, utilized WebP format to reduce image sizes, and incorporated a lightweight caching system.",
              highlighted: [
                {
                  text: "Performance Optimization:",
                  style: "highlight-underline",
                },
                { text: "LazyLoading", style: "highlight-bold" },
                { text: "loading", style: "highlight-bold" },
                { text: "WebP format", style: "highlight-bold" },
                { text: "caching", style: "highlight-bold" },
              ],
            },
            {
              text: "Visual effects and animations are orchestrated using GSAP to ensure smooth transitions and optimal performance.",
              highlighted: [
                { text: "Visual effects", style: "highlight-bold" },
                { text: "GSAP", style: "highlight-bold" },
                { text: "smooth transitions", style: "highlight-bold" },
                { text: "optimal performance", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Development practices: Adoption of agile practices with a well-defined backlog, precise task estimation, and a strong focus on DoD and DoR to ensure code quality and maintainability.",
              highlighted: [
                {
                  text: "Development practices:",
                  style: "highlight-underline",
                },
                { text: "agile practices", style: "highlight-bold" },
                { text: "backlog", style: "highlight-italic" },
                { text: "estimation", style: "highlight-bold" },
                { text: "DoD", style: "highlight-italic" },
                { text: "DoR", style: "highlight-italic" },
                { text: "quality", style: "highlight-bold" },
                { text: "maintainability", style: "highlight-bold" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "The site has also served as a learning ground for various technologies. The Scrum Nomad experimented with different solutions to find the best approaches.",
              highlighted: [
                { text: "technologies", style: "highlight-bold" },
                { text: "solutions", style: "highlight-bold" },
                { text: "best approaches", style: "highlight-bold" },
              ],
            },
            {
              text: "He created all the site's animations himself, preferring to control every aspect to better understand their functionality.",
              highlighted: [
                { text: "himself", style: "highlight-bold" },
                { text: "control", style: "highlight-bold" },
                { text: "better understand", style: "highlight-bold" },
              ],
            },
            {
              text: "This process allowed him to master GSAP and continuously improve the site's performance.",
              highlighted: [
                { text: "master GSAP", style: "highlight-bold" },
                {
                  text: "improve",
                  style: "highlight-bold",
                },
                { text: "performance", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "He is well aware that the methods used may not always be the most suitable, firmly believing that there is always room for improvement.",
          highlighted: [
            { text: "believing", style: "highlight-bold" },
            { text: "always room", style: "highlight-bold" },
            { text: "improvement", style: "highlight-bold" },
          ],
        },
      ],
      character17: [
        {
          type: "subtitle",
          content: "Other Experiences",
          highlighted: [
            {
              text: "Other Experiences",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "The Scrum Nomad also has a rich academic background in geology, with diverse experiences in various contexts.",
              highlighted: [
                { text: "rich academic background", style: "highlight-bold" },
                { text: "geology", style: "highlight-italic" },
                { text: "diverse", style: "highlight-bold" },
                { text: "various", style: "highlight-bold" },
              ],
            },
            {
              text: "He completed internships in Réunion Island, working on the active volcano Piton de la Fournaise, and in Greece, studying the geology of the Cyclades.",
              highlighted: [
                { text: "Réunion Island", style: "highlight-bold" },
                { text: "active volcano", style: "highlight-bold" },
                { text: "Greece", style: "highlight-bold" },
                { text: "Cyclades", style: "highlight-bold" },
              ],
            },
            {
              text: "Passionate about petrology, he also spent significant time in the lab analyzing the geochemistry and structure of various rocks.",
              highlighted: [
                { text: "petrology", style: "highlight-bold" },
                { text: "lab", style: "highlight-bold" },
                { text: "geochemistry", style: "highlight-bold" },
                { text: "structure", style: "highlight-bold" },
              ],
            },
            {
              text: "These experiences taught him the rigor and methodology needed to tackle any challenge, bringing a methodical and focused approach to his projects.",
              highlighted: [
                { text: "experiences", style: "highlight-bold" },
                { text: "rigor", style: "highlight-bold" },
                { text: "methodology", style: "highlight-bold" },
                { text: "challenge", style: "highlight-bold" },
                { text: "methodical", style: "highlight-bold" },
                { text: "focused", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      character18: [
        {
          type: "subtitle",
          content: "Testimonials and References",
          highlighted: [
            {
              text: "Testimonials and References",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Testimonials and references from colleagues and clients highlight the positive impact of the Scrum Nomad on their projects.",
              highlighted: [
                { text: "positive impact", style: "highlight-italic" },
              ],
            },
            {
              text: "His methodical approach, technical expertise, and ability to coach teams are praised.",
              highlighted: [
                { text: "methodical", style: "highlight-bold" },
                { text: "technical expertise", style: "highlight-bold" },
                { text: "ability to coach", style: "highlight-bold" },
              ],
            },
            {
              text: "Additional recommendations are available upon request. To obtain this information, you can reach out via the contact form available on this site.",
              highlighted: [
                { text: "upon request", style: "highlight-bold" },
                { text: "contact form", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      //Parcours
      character19: [
        {
          type: "title",
          content: "Academic Background",
          highlighted: [
            { text: "Academic Background", style: "highlight-underline2" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Hello, I'm EducBot, here to present the academic background and foundational skills of Scrum Nomad. Here's an overview of his certifications, formations, and diplomas.",
          highlighted: [
            {
              text: "EducBot",
              style: "highlight-italic2",
            },
            {
              text: "academic",
              style: "highlight-bold2",
            },
            {
              text: "background",
              style: "highlight-bold2",
            },
            {
              text: "foundational",
              style: "highlight-bold2",
            },
            {
              text: "skills",
              style: "highlight-bold2",
            },
            {
              text: "certifications",
              style: "highlight-bold2",
            },
            {
              text: "formations",
              style: "highlight-bold2",
            },
            {
              text: "diplomas",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Professional Certifications",
          highlighted: [
            {
              text: "Professional Certifications",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "To support his transition into the field of agility and development, Scrum Nomad has obtained several certifications and education courses:",
          highlighted: [
            {
              text: "certifications",
              style: "highlight-bold2",
            },
            {
              text: "education courses:",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: [
            "Certified Scrum Master - January 2021: Scrum Master certification training.",
            "Leading SAFe - November 2021: Training on the SAFe environment and the required role.",
            "Facilitating Virtual - February 2022: Training to understand and conduct virtual classes.",
            "Argue to Persuade - December 2021: Training on structuring solid arguments and persuading effectively in a professional setting.",
          ],
          highlighted: [
            {
              text: "Certified Scrum Master",
              style: "highlight-underline2",
            },
            { text: "Leading SAFe", style: "highlight-underline2" },
            {
              text: "Facilitating Virtual",
              style: "highlight-underline2",
            },
            {
              text: "Argue to Persuade",
              style: "highlight-underline2",
            },
            {
              text: "certification",
              style: "highlight-bold2",
            },
            {
              text: "SAFe environment",
              style: "highlight-bold2",
            },
            {
              text: "understand",
              style: "highlight-bold2",
            },
            {
              text: "conduct",
              style: "highlight-bold2",
            },
            {
              text: "structuring",
              style: "highlight-bold2",
            },
            {
              text: "arguments",
              style: "highlight-bold2",
            },
            {
              text: "persuading",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Training as a trainer",
          highlighted: [
            {
              text: "Training",
              style: "highlight-underline2",
            },
            {
              text: "trainer",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "The Scrum Nomad has facilitated virtual classes to train participants in agile practices, with a particular focus on Scrum.",
          highlighted: [
            {
              text: "facilitated",
              style: "highlight-bold2",
            },
            {
              text: "virtual classes",
              style: "highlight-bold2",
            },
            {
              text: "agile",
              style: "highlight-bold2",
            },
            {
              text: "practices,",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: ["Scrum Master Agile method - April 2022"],
          highlighted: [
            {
              text: "Scrum Master Agile method",
              style: "highlight-underline2",
            },
          ],
        },
      ],
      character20: [
        {
          type: "subtitle",
          content: "Academic diplomas",
          highlighted: [
            {
              text: "Academic diplomas",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "The Scrum Nomad also has a strong academic background in science, particularly in geological research.",
          highlighted: [
            {
              text: "academic background",
              style: "highlight-bold2",
            },
            {
              text: "sciences",
              style: "highlight-bold2",
            },
            {
              text: "geological research",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: [
            "Master's Degree in Georesources and Geomaterials - 2018. Université de Toulouse, France",
            "Master's Degree in Internal Dynamics of the Earth - 2017. Centre de recherche en sciences de l'Univers, Université d'Orléans, France",
            "Bachelor in Geochemistry and Petrology - 2015. Université Blaise Pascal, Clermont-Ferrand, France",
            "Bachelor in Hydrology and Sedimentology - 2014. Université François Rabelais, Tours, France",
          ],
          highlighted: [
            { text: "Master's degree", style: "highlight-bold2" },
            { text: "Bachelor", style: "highlight-bold2" },
            { text: "Georesources", style: "highlight-bold2" },
            { text: "Geomaterials", style: "highlight-bold2" },
            { text: "Internal Dynamics", style: "highlight-bold2" },
            { text: "Geochemistry", style: "highlight-bold2" },
            { text: "Petrology", style: "highlight-bold2" },
            { text: "Hydrology", style: "highlight-bold2" },
            { text: "Sedimentology", style: "highlight-bold2" },
          ],
        },
      ],
      character21: [
        {
          type: "title",
          content: "Contact",
          highlighted: [{ text: "Contact", style: "highlight-underline" }],
        },
        {
          type: "paragraph",
          content:
            "To get in touch with him, use the form below. Alternatively, you can also reach out to him via LinkedIn or X.",
          highlighted: [
            {
              text: "in touch",
              style: "highlight-bold",
            },
            {
              text: "formulformaire",
              style: "highlight-bold",
            },
            {
              text: "LinkedIn",
              style: "highlight-bold",
            },
            {
              text: "X",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "socialLink",
          content: {
            href: "https://www.linkedin.com/in/pierre-filliau/",
            srcPng: linkedin,
            srcWebp: linkedinWebp,
            alt: "LinkedIn",
            text: "Scrum Nomad LinkedIn",
          },
          highlighted: [],
        },
        {
          type: "socialLink",
          content: {
            href: "https://x.com/PierreF34083004",
            srcPng: twitter,
            srcWebp: twitterWebp,
            alt: "twitterX",
            text: "Scrum Nomad X",
          },
          highlighted: [],
        },
        {
          type: "paragraph",
          content:
            "Whether you have questions, want to propose a project, or are seeking his services for Scrum coaching, Scrum Master training, or to strengthen your team, he will be happy to respond as soon as possible.",
          highlighted: [
            {
              text: "questions",
              style: "highlight-bold",
            },
            {
              text: "propose a project",
              style: "highlight-bold",
            },
            {
              text: "Scrum coaching",
              style: "highlight-bold",
            },
            {
              text: "training",
              style: "highlight-bold",
            },
            {
              text: "Scrum Master",
              style: "highlight-bold",
            },
            {
              text: "strengthen your team",
              style: "highlight-bold",
            },
            {
              text: "as soon as possible",
              style: "highlight-bold",
            },
          ],
        },
      ],
      character22: [
        {
          type: "subtitle",
          content: "Would you like to download the Scrum Nomad's résumé?",
          highlighted: [
            { text: "download", style: "highlight-underline" },
            { text: "download", style: "highlight-underline" },
          ],
        },
        {
          type: "paragraph",
          content: "To do this, simply click on the button right next to me.",
          highlighted: [
            {
              text: "click",
              style: "highlight-bold",
            },
            {
              text: "button",
              style: "highlight-bold",
            },
          ],
        },
      ],
    },
    hovers: {
      musicStart: "Play Music",
      musicStop: "Pause Music",
      langToEnglish: "Switch to English",
      scrollDown: "Discovery mode",
      fullScreenON: "Full Screen",
      fullScreenOFF: "Exit Full Screen",
      viewSection: "View complete Section",
      closeBubble: "Close Bubble",
      closeSection: "Close Section",
      nextSlide: "Next",
      prevSlide: "Previous",
      restart: "Restart",
      hoverMessages: [
        "How are you?",
        "Nice to see you!",
        "Bip Bop",
        "Bop",
        "0001100010 01101111 01110000",
        "⏚⟒⍀ ⏚⍜⍀",
        "⏚⍜⎐⟟⍜⎍⍀",
        "Hey, click on that bubble!",
        "...translation in progress...",
        "Where is my favorite gear?",
        "Hey, I have something to say",
        "Am I missing a screw?",
        "I am your galactic guide",
        "404 Message Not Found",
        "You hovered over the right robot",
        "Robot friendly, human approved",
        "Have you seen my screw?",
        "I know all the secrets of this site",
        "The future is now! Uh, wait...",
        "I'm smarter than a toaster",
        "I speak fluent binary",
      ],
    },
    geology: {
      title: "Geology Page",
      subtitle: "Nothing but a page...",
    },
    loadingPage: {
      howToInterractWebsite: "How do I interact with the website?",
      minimap: "Minimap",
      discovery: "Discovery mode",
      expand: "Full screen",
      lang: "Change language",
      music: "Mute music",
      howToInterractCharacter: "How do I interact with the robot?",
      nextSlide: "Continue reading",
      seeSection: "Open the full section",
      closeBubble: "Close the bubble",
    },
    contactForm: {
      title: "Contact the Scrum Nomad",
      buttonSend: "Send",
      placeholderName: "Name",
      placeholderMail: "Mail",
      placeholderObject: "Object",
      placeholderMessage: "Your message...",
      successMessage: "Your message has been sent successfully!",
      errorMessage: "An error occurred. Please try again later.",
      downloadResume: {
        downloadTooltip: "Download my résumé",
        confirmPart1: "Do you really want to",
        confirmPart2: "download my résumé?",
        confirmYes: "Yes",
        confirmNo: "No",
      },
    },
    otherTexts: { lastSlideMessage: "" },
  },
  fr: {
    navbar: {
      home: "Bienvenue",
      skills: {
        main: "Compétences",
        dropAgil: "Agiles",
        dropTech: "Techniques",
      },
      role: "Rôles",
      project: {
        main: "Projets",
        persoProj: "Personnels",
        proProj: "Professionnels",
      },
      journey: "Parcours",
      contact: { main: "Contact", resume: "CV" },
    },
    minimap: {
      backgroundHover: "Carte",
      hover1: "Bienvenue",
      hover2: "Navigation",
      hover3: "Présentation",
      hover4: "Compétences",
      hover5: "Scrum Master",
      hover6: "Philosophie",
      hover7: "Développeur",
      hover8: "Technologie",
      hover9: "Postures",
      hover10: "Rôles",
      hover11: "Projets Pro",
      hover12: "Projet Perso",
      hover13: "Divers",
      hover14: "Certifications",
      hover15: "Diplomes",
      hover16: "Contact",
      hover17: "CV",
    },
    characters: {
      //Intro
      character1: [
        {
          type: "title",
          content: "Bienvenue dans l'univers du Scrum Nomad !",
          highlighted: [{ text: "Scrum Nomad", style: "highlight-slide" }],
        },
        {
          type: "subtitle",
          content: "Je suis IntRobot, pour vous guider à travers ce site.",
          highlighted: [{ text: "IntRobot", style: "highlight-italic" }],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad, c'est mon créateur et le créateur de cet espace virtuel, un Scrum Master passionné, originaire de France, et vivant à New York.",
              highlighted: [
                { text: "Scrum Nomad", style: "highlight-slide" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "France", style: "highlight-bold" },
                { text: "New york", style: "highlight-bold" },
              ],
            },
            {
              text: "Il possède une expérience unique allant des études scientifiques avancées au développement front-end et bien sûr à l'agilité.",
              highlighted: [
                { text: "études scientifiques", style: "highlight-bold" },
                { text: "front-end", style: "highlight-bold" },
                { text: "l'agilité", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Actuellement, le Scrum Nomad n'est pas disponible. Son autorisation d'emploi arrive fin août 2024.",
              highlighted: [
                {
                  text: "Scrum Nomad",
                  style: "highlight-slide",
                },
                {
                  text: "n'est pas disponible",
                  style: "highlight-bold",
                },
                {
                  text: "fin août 2024",
                  style: "highlight-bold",
                },
              ],
            },
            {
              text: "En attendant, il voyage à travers des constellations agiles et explore des dimensions inconnues pour partager ses chroniques et transmettre ses découvertes en agilité.",
              highlighted: [
                {
                  text: "ses découvertes",
                  style: "highlight-bold",
                },
                {
                  text: "en agilité",
                  style: "highlight-bold",
                },
              ],
            },
            {
              text: "Ce site a été conçu pour partager ses compétences, son parcours, et sa vision de l'agilité avec vous.",
              highlighted: [
                {
                  text: "sa vision de l'agilité",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Chaque section que vous allez explorer est une fenêtre sur ses réalisations, ses projets, et sa philosophie de travail.",
              highlighted: [],
            },
            {
              text: "Que vous soyez à la recherche d'un Scrum Master technique ou curieux de découvrir son parcours, vous êtes au bon endroit.",
              highlighted: [
                {
                  text: "Scrum Master technique",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
      ],
      character2: [
        {
          type: "subtitle",
          content: "Comment naviguer sur ce site ?",
          highlighted: [{ text: "naviguer", style: "highlight-bold" }],
        },

        {
          type: "paragraph",
          content:
            "Ce site est conçu comme une exploration interactive des compétences et expériences du Scrum Nomad.",
          highlighted: [{ text: "Scrum Nomad", style: "highlight-slide" }],
        },

        {
          type: "list",
          content: [
            "Sections Interactives: Chaque section du site est représentée par un robot et une couleur unique. Cliquez sur les robots pour lire sa section.",
            "Carte: Utilisez la carte située sur le côté droit pour naviguer entre les différentes sections.",
            "Musique de Fond: Une musique d'ambiance se lance à l'introduction. Si elle vous gène, vous pouvez la couper !",
            "Langues: Le site est disponible en français et en anglais. Vous pouvez changer de langue à tout moment.",
          ],
          highlighted: [
            { text: "Sections Interactives:", style: "highlight-underline" },
            { text: "Carte:", style: "highlight-underline" },
            { text: "Musique de Fond:", style: "highlight-underline" },
            { text: "Langues:", style: "highlight-underline" },
            { text: "un robot", style: "highlight-italic" },
            { text: "couleur unique", style: "highlight-italic" },
            { text: "le côté droit", style: "highlight-italic" },
            { text: "en français", style: "highlight-italic" },
            { text: "en anglais", style: "highlight-italic" },
          ],
        },

        {
          type: "paragraph",
          content:
            "Pour continuer la navigation, scrollez vers le bas et discutez avec les différents robots.",
          highlighted: [
            { text: "navigation", style: "highlight-italic" },
            { text: "scrollez vers le bas", style: "highlight-italic" },
            { text: "robots", style: "highlight-bold" },
          ],
        },
      ],
      character3: [
        {
          type: "subtitle",
          content: "Mais vous pouvez vous demander, qui est le Scrum Nomad ?",
          highlighted: [
            { text: "qui", style: "highlight-italic" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad est un passionné d'agilité venu tout droit de France, vivant à New York depuis deux ans.",
              highlighted: [
                { text: "passionné d'agilité", style: "highlight-bold" },
                { text: "France", style: "highlight-italic" },
                { text: "New York", style: "highlight-italic" },
              ],
            },
            {
              text: "Son parcours est unique: après des études en géologie, il a bifurqué vers le développement web puis est devenu Scrum Master.",
              highlighted: [
                { text: "unique", style: "highlight-bold" },
                { text: "géologie", style: "highlight-italic" },
                { text: "développement web", style: "highlight-italic" },
                { text: "Scrum Master", style: "highlight-bold" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "En attendant de pouvoir travailler aux USA, il a créé ce site. Pourquoi ?",
              highlighted: [{ text: "Pourquoi ?", style: "highlight-italic" }],
            },
            {
              text: "Parce qu'il aime les projets ambitieux et voulait se donner le challenge de créer entièrement son propre site.",
              highlighted: [
                { text: "projets ambitieux", style: "highlight-bold" },
                { text: "challenge", style: "highlight-bold" },
                { text: "entièrement", style: "highlight-bold" },
              ],
            },
            {
              text: "Il voulait créer une carte de visite interactive.",
              highlighted: [
                { text: "visibilité", style: "highlight-italic" },
                { text: "carte de visite", style: "highlight-bold" },
              ],
            },
            {
              text: "Ce site est à la fois son portfolio et son espace de partage de sa vision de Scrum.",
              highlighted: [
                {
                  text: "sa vision de Scrum",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
      ],

      character4: [
        {
          type: "subtitle",
          content: "Un Scrum Master technique ?",
          highlighted: [
            {
              text: "Scrum Master technique",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le développement de ce site lui a permis d'apprendre des technologies comme React ou GSAP.",
              highlighted: [
                { text: "d'apprendre", style: "highlight-italic" },
                { text: "React", style: "highlight-bold" },
                { text: "GSAP", style: "highlight-bold" },
              ],
            },
            {
              text: "Il est toujours resté fidèle aux bonnes pratiques de développement, surmontant de nombreux défis techniques. ",
              highlighted: [
                { text: "bonnes pratiques", style: "highlight-bold" },
                { text: "défis techniques", style: "highlight-bold" },
              ],
            },
            {
              text: "Cela l'a aidé à mieux maîtriser les différents aspects d'un projet, de l'architecture au front-end.",
              highlighted: [
                { text: "l'architecture", style: "highlight-bold" },
                { text: "front-end", style: "highlight-bold" },
              ],
            },
            {
              text: "Il considère indispensable pour un Scrum Master Technique d'avoir une réelle compréhension des technologies de son projet.",
              highlighted: [
                {
                  text: "Scrum Master technique",
                  style: "highlight-underline",
                },
                {
                  text: "des technologies",
                  style: "highlight-bold",
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "L'objectif de ce site ? Montrer ses compétences et partager sa vision de l'agilité de manière ludique et originale.",
          highlighted: [
            { text: "L'objectif de ce site ?", style: "highlight-underline" },
            { text: "ludique et originale", style: "highlight-italic" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Prêt à découvrir plus ? Allez voir la Skill-team un peu plus bas, pour comprendre comment il peut apporter de la valeur à votre équipe et à vos projets.",
          highlighted: [
            { text: "Skill-team", style: "highlight-italic" },
            { text: "apporter de la valeur", style: "highlight-bold" },
            { text: "votre équipe", style: "highlight-bold" },
            { text: "vos projets", style: "highlight-bold" },
          ],
        },
      ],
      //agility skills
      character5: [
        {
          type: "title",
          content:
            "La Skill-Team est là ! Parlons des compétences du Scrum Nomad.",
          highlighted: [
            { text: "Skill-team", style: "highlight-bold" },
            { text: "compétences", style: "highlight-bold" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "subtitle",
          content:
            "Avec les prochains robots, nous représentons la section skill-team, nous sommes toujours hyper rigoureux sur les talents et les compétences du Scrum Nomad. ",
          highlighted: [
            { text: "skill-team", style: "highlight-bold" },
            { text: "talents", style: "highlight-bold" },
            { text: "compétences", style: "highlight-bold" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Nous allons parler des compétences agile avec les robots avec les textes turquoises, puis de ses compétences techniques en orange.",
          highlighted: [
            { text: "compétences agile", style: "highlight-bold" },
            { text: "turquoises", style: "highlight-turquoise" },
            { text: "compétences", style: "highlight-bold" },
            { text: "techniques", style: "highlight-bold" },
            { text: "orange", style: "highlight-orange" },
          ],
        },
      ],
      character6: [
        {
          type: "title",
          content: "Un Scrum Nomad agile ?",
          highlighted: [
            { text: "Scrum Nomad", style: "highlight-slide" },
            { text: "agile", style: "highlight-bold" },
          ],
        },
        {
          type: "subtitle",
          content:
            "Tout d'abord, parlons agilité et philosophie agile du Scrum Nomad.",
          highlighted: [
            { text: "agilité", style: "highlight-bold" },
            { text: "philosophie", style: "highlight-bold" },
            { text: "agile", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad a joué plusieurs rôles clés dans les équipes agiles, notamment en tant que Scrum Master Technique ou Coach Scrum.",
              highlighted: [
                { text: "rôles clés", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Technique", style: "highlight-bold" },
                { text: "Coach Scrum", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a développé une expertise dans la facilitation des cérémonies Scrum, s'adaptant aux besoins de chaque projet.",
              highlighted: [
                { text: "cérémonies Scrum", style: "highlight-italic" },
                { text: "besoins", style: "highlight-bold" },
                { text: "chaque projet", style: "highlight-bold" },
              ],
            },
            {
              text: "Il excelle dans la facilitation des rétrospectives de sprint, aidant l'équipe à se recentrer sur ses objectifs, à créer des conflits constructifs et à identifier les axes d'amélioration.",
              highlighted: [
                { text: "rétrospectives de sprint", style: "highlight-italic" },
                { text: "recentrer", style: "highlight-bold" },
                { text: "conflits constructifs", style: "highlight-bold" },
                { text: "axes", style: "highlight-bold" },
                { text: "d'amélioration", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Une jeune équipe agile ne comprend pas toujours pleinement la nature empirique du cadre Scrum. C'est pourquoi il adopte une position de mentor et de coach pour renforcer la culture d'inspection et d'adaptation.",
          highlighted: [
            { text: "empirique", style: "highlight-bold" },
            { text: "mentor", style: "highlight-bold" },
            { text: "cadre Scrum", style: "highlight-bold" },
            { text: "coach", style: "highlight-bold" },
            { text: "d'inspection", style: "highlight-italic" },
            { text: "d'adaptation", style: "highlight-italic" },
          ],
        },
      ],
      character7: [
        {
          type: "subtitle",
          content: "Mais quels sont les principaux pilliers de son  agilité ?",
          highlighted: [
            { text: "principaux pilliers", style: "highlight-bold" },
            { text: "son ", style: "highlight-italic" },
            { text: "agilité", style: "highlight-bold" },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Challenger ses compétences, son rôle et sa compréhension des problèmes, tout en mettant en avant la transparence.",
              highlighted: [
                {
                  text: "Challenger ses compétences",
                  style: "highlight-underline",
                },
                {
                  text: "la transparence",
                  style: "highlight-italic",
                },
              ],
              imageSrc: discipline,
              imageSrcWebp: disciplineWebp,
              imageAlt: "discipline",
              highlightText: "Discipline",
              highlightPosition: { top: "-8vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Respecter les valeurs Scrum (Courage, Focus, Engagement, Respect et Ouverture) pour permettre une culture agile.",
              highlighted: [
                {
                  text: "Respecter les valeurs Scrum",
                  style: "highlight-underline",
                },
                {
                  text: "(Courage",
                  style: "highlight-italic",
                },
                {
                  text: "Focus",
                  style: "highlight-italic",
                },
                {
                  text: "Engagement",
                  style: "highlight-italic",
                },
                {
                  text: "Respect",
                  style: "highlight-italic",
                },
                {
                  text: "Ouverture)",
                  style: "highlight-italic",
                },
                {
                  text: "culture agile",
                  style: "highlight-bold",
                },
              ],
              imageSrc: behavior,
              imageSrcWebp: behaviorWebp,
              imageAlt: "behavior",
              highlightText: "Comportement",
              highlightPosition: { top: "1vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Travailler sur la valeur produit, en veillant à ce que l'équipe Scrum et les parties prenantes comprennent l'impact des Product Backlog Increment (PBI).",
              highlighted: [
                {
                  text: "Travailler sur la valeur",
                  style: "highlight-underline",
                },
                {
                  text: "produit",
                  style: "highlight-underline",
                },
                {
                  text: "Product Backlog",
                  style: "highlight-italic",
                },
                {
                  text: "Increment",
                  style: "highlight-italic",
                },
                {
                  text: "PBI",
                  style: "highlight-bold",
                },
              ],
              imageSrc: clientValue,
              imageSrcWebp: clientValueWebp,
              imageAlt: "clientValue",
              highlightText: "Valeur",
              highlightPosition: { top: "10vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Favoriser l'entraide entre les membres de l'équipe Scrum, permettant la création de nouvelles compétences par le partage d'expérience et l'apprentissage collectif.",
              highlighted: [
                {
                  text: "Favoriser l'entraide",
                  style: "highlight-underline",
                },
                {
                  text: "nouvelles compétences",
                  style: "highlight-bold",
                },
                {
                  text: "partage d'expérience",
                  style: "highlight-bold",
                },
                {
                  text: "l'apprentissage collectif",
                  style: "highlight-bold",
                },
              ],
              imageSrc: helpingTeam,
              imageSrcWebp: helpingTeamWebp,
              imageAlt: "helpingTeam",
              highlightText: "Aider l'équipe",
              highlightPosition: { top: "19vw", left: "40vw" },
              textPosition: "right",
            },
          ],
        },
      ],
      character8: [
        {
          type: "subtitle",
          content: "Sa philosophie agile, un état d'esprit.",
          highlighted: [
            { text: "état d'esprit", style: "highlight-bold" },
            { text: "philosophie", style: "highlight-bold" },
            { text: "agile", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "La philosophie agile du Scrum Nomad repose sur l'amélioration du travail d'équipe et des processus.",
              highlighted: [
                { text: "l'amélioration", style: "highlight-italic" },
                { text: "travail", style: "highlight-bold" },
                { text: "d'équipe", style: "highlight-bold" },
                { text: "des processus", style: "highlight-bold" },
              ],
            },
            {
              text: "Scrum offre de nombreux axes pour maximiser leur efficacité. Il se veut Servant Leader pour optimiser les équipes.",
              highlighted: [
                { text: "efficacité", style: "highlight-bold" },
                { text: "Servant Leader", style: "highlight-italic" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Les softs-skills essentielles du Scrum Nomad dans une équipe Scrum incluent:",
          highlighted: [{ text: "softs-skills", style: "highlight-bold" }],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Pédagogie: Le Scrum Nomad aide les membres de l'équipe à bien comprendre le cadre Scrum et à souligner ses valeurs et principes.",
              highlighted: [
                {
                  text: "Pédagogie:",
                  style: "highlight-underline",
                },
                {
                  text: "Scrum Nomad",
                  style: "highlight-slide",
                },
                {
                  text: "cadre Scrum",
                  style: "highlight-bold",
                },
                {
                  text: "ses valeurs",
                  style: "highlight-bold",
                },
                {
                  text: "principes",
                  style: "highlight-bold",
                },
              ],
              imageSrc: teaching,
              imageSrcWebp: teachingWebp,
              imageAlt: "teaching",
              highlightText: "Compétences pédagogiques",
              highlightPosition: { top: "-6vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Facilitation: Il guide les équipes à trouver leurs propres solutions et à prendre les meilleures décisions, favorisant ainsi une collaboration positive.",
              highlighted: [
                {
                  text: "Facilitation:",
                  style: "highlight-underline",
                },
                {
                  text: "propres solutions",
                  style: "highlight-bold",
                },
                {
                  text: "meilleures décisions",
                  style: "highlight-bold",
                },
                {
                  text: "collaboration positive",
                  style: "highlight-bold",
                },
              ],
              imageSrc: facilitation,
              imageSrcWebp: facilitationWebp,
              imageAlt: "facilitation",
              highlightText: "Facilitateur",
              highlightPosition: { top: "0vw", left: "55vw" },
              textPosition: "left",
            },
            {
              content:
                "Coach: Améliorer la capacité de l'équipe à apprendre et à atteindre ses objectifs, en favorisant que chaque membre prenne le contrôle de ses décisions.",
              highlighted: [
                {
                  text: "Coach:",
                  style: "highlight-underline",
                },
                {
                  text: "apprendre",
                  style: "highlight-bold",
                },
                {
                  text: "contrôle de ses ",
                  style: "highlight-bold",
                },
                {
                  text: "décisions",
                  style: "highlight-bold",
                },
              ],
              imageSrc: coaching,
              imageSrcWebp: coachingWebp,
              imageAlt: "coaching",
              highlightText: "Compétences de coaching",
              highlightPosition: { top: "6vw", left: "40vw" },
              textPosition: "right",
            },
            {
              content:
                "Développeur: Adaptée au cas par cas selon les technologies et les contraintes du projet. Il est crucial de prendre en compte l'évolution rapide des techniques, notamment à l'ère des IA.",
              highlighted: [
                {
                  text: "Développeur:",
                  style: "highlight-underline",
                },
                {
                  text: "technologies",
                  style: "highlight-bold",
                },
                {
                  text: "contraintes",
                  style: "highlight-bold",
                },

                {
                  text: "l'évolution rapide",
                  style: "highlight-bold",
                },
                {
                  text: "l'ère des IA",
                  style: "highlight-italic",
                },
              ],
              imageSrc: technicalExcellence,
              imageSrcWebp: technicalExcellenceWebp,
              imageAlt: "helpingTeam",
              highlightText: "Excellence technique",
              highlightPosition: { top: "13vw", left: "55vw" },
              textPosition: "left",
            },
            {
              content:
                "Servant leader: Le Scrum Nomad sert le Product Owner, l'équipe de développement et l'organisation en aidant chacun à accroître ses capacités. Son succès est déterminé par le succès de toute l'équipe.",
              highlighted: [
                {
                  text: "Servant leader:",
                  style: "highlight-underline",
                },
                {
                  text: "Product Owner",
                  style: "highlight-italic",
                },
                {
                  text: "l'équipe de développement",
                  style: "highlight-italic",
                },
                {
                  text: "l'organisation",
                  style: "highlight-italic",
                },
                {
                  text: "le succès de",
                  style: "highlight-bold",
                },
                {
                  text: "toute l'équipe",
                  style: "highlight-bold",
                },
              ],
              imageSrc: serventLeader,
              imageSrcWebp: serventLeaderWebp,
              imageAlt: "serventLeader",
              highlightText: "Servant leadership",
              highlightPosition: { top: "20vw", left: "40vw" },
              textPosition: "right",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Le Scrum Nomad croit fermement que l'amélioration continue passe par l'organisation autonome des équipes, utilisant les rétrospectives et les daily meetings pour identifier les difficultés et supprimer les obstacles.",
          highlighted: [
            { text: "l'amélioration continue", style: "highlight-italic" },
            { text: "l'organisation", style: "highlight-italic" },
            { text: "autonome", style: "highlight-italic" },
            { text: "difficultés", style: "highlight-bold" },
            { text: "obstacles", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Il encourage la collaboration en favorisant les affinités et le partage des connaissances, assurant ainsi une équipe cross-fonctionnelle évoluant grâce à la coopération.",
          highlighted: [
            {
              text: "collaboration",
              style: "highlight-bold",
            },
            {
              text: "affinités",
              style: "highlight-bold",
            },
            {
              text: "connaissances",
              style: "highlight-bold",
            },
            {
              text: "cross-fonctionnelle",
              style: "highlight-italic",
            },
            {
              text: "coopération",
              style: "highlight-bold",
            },
          ],
        },
      ],
      character9: [
        {
          type: "subtitle",
          content: "Challenges et Apprentissage",
          highlighted: [
            {
              text: "Challenges",
              style: "highlight-underline",
            },
            {
              text: "Apprentissage",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Gérer les conflits au sein des équipes agiles fait partie du rôle du Scrum Nomad.",
              highlighted: [
                { text: "Gérer les conflits", style: "highlight-bold" },
              ],
            },
            {
              text: "Il cherche à transformer les différences de points de vue en nouvelles idées, favorisant des débats constructifs pour créer des solutions concrètes.",
              highlighted: [
                { text: "transformer", style: "highlight-bold" },
                { text: "idées", style: "highlight-bold" },
                { text: "débats constructifs", style: "highlight-bold" },
                { text: "solutions concrètes", style: "highlight-bold" },
              ],
            },
            {
              text: "Il est attentif à identifier et résoudre les obstacles dès qu'ils apparaissent pour éviter qu'ils ne freinent le processus.",
              highlighted: [
                { text: "identifier", style: "highlight-bold" },
                { text: "résoudre", style: "highlight-bold" },
              ],
            },
            {
              text: "Grâce aux valeurs Scrum, il a réussi à guider des collègues réticents à Scrum à adopter des méthodes de travail plus efficaces et agiles.",
              highlighted: [
                { text: "valeurs Scrum", style: "highlight-italic" },
                { text: "guider", style: "highlight-bold" },
                { text: "adopter", style: "highlight-bold" },
                { text: "efficaces", style: "highlight-bold" },
                { text: "agiles", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subtitle",
          content: "Avenir et Développement",
          highlighted: [
            {
              text: "Avenir",
              style: "highlight-underline",
            },
            {
              text: "Développement",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "En tant que Scrum Master Professionnel, le Scrum Nomad souhaite mettre en pratique ses compétences et ses connaissances en agilité.",
              highlighted: [
                { text: "Scrum Master", style: "highlight-italic" },
                { text: "Professionnel", style: "highlight-italic" },
                { text: "compétences", style: "highlight-bold" },
                { text: "connaissances", style: "highlight-bold" },
              ],
            },
            {
              text: "Il envisage de composer une conférence sur l'agilité, avec un focus particulier sur Scrum, et de se spécialiser dans le conseil et le coaching Scrum.",
              highlighted: [
                { text: "conférence sur l'agilité", style: "highlight-bold" },
                { text: "Scrum", style: "highlight-italic" },
                { text: "conseil", style: "highlight-bold" },
                { text: "coaching", style: "highlight-bold" },
              ],
            },
            {
              text: "Son objectif est de maximiser les bénéfices de Scrum en promouvant l'état d'esprit agile et les processus.",
              highlighted: [
                { text: "bénéfices de Scrum", style: "highlight-bold" },
                { text: "l'état d'esprit", style: "highlight-italic" },
                { text: "processus", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      //technical skills
      character10: [
        {
          type: "title",
          content: "Le Scrum Nomad, un développeur ?",
          highlighted: [
            {
              text: "développeur",
              style: "highlight-italic2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Oui, un développeur front-end.",
          highlighted: [
            {
              text: "Oui",
              style: "highlight-underline2",
            },
            {
              text: "front-end",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Bonjour, explorateur ! Je suis TechBot de la Skill-team, et on va se concentrer ici sur les compétences techniques du Scrum Nomad.",
          highlighted: [
            {
              text: "TechBot",
              style: "highlight-italic2",
            },
            {
              text: "compétences techniques",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad a développé ce site en utilisant React, GSAP, CSS, HTML5 et JavaScript.",
              highlighted: [
                { text: "React", style: "highlight-bold2" },
                { text: "GSAP", style: "highlight-bold2" },
                { text: "CSS", style: "highlight-bold2" },
                { text: "HTML5", style: "highlight-bold2" },
                { text: "JavaScript", style: "highlight-bold2" },
              ],
            },
            {
              text: "Professionnellement, il a surtout travaillé avec Angular et JavaScript, ainsi qu'avec PHP et possède des connaissances en Java.",
              highlighted: [
                { text: "Angular", style: "highlight-bold2" },
                { text: "Professionnellement", style: "highlight-bold2" },
                { text: "JavaScript", style: "highlight-bold2" },
                { text: "PHP", style: "highlight-bold2" },
                { text: "Java", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad apprécie le développement front-end pour son impact direct sur l'expérience utilisateur.",
              highlighted: [
                { text: "front-end", style: "highlight-bold2" },
                { text: "l'expérience utilisateur", style: "highlight-bold2" },
              ],
            },
            {
              text: "Il valorise les bonnes pratiques de développement, avec une attention particulière aux performances et à l'approche écologique du green-coding.",
              highlighted: [
                { text: "performances", style: "highlight-bold2" },
                { text: "green-coding", style: "highlight-bold2" },
              ],
            },
          ],
        },
      ],
      character11: [
        {
          type: "subtitle",
          content: "Quelles technologies sont utilisées ?",
          highlighted: [
            {
              text: "technologies",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Pour enrichir ses projets, le Scrum Nomad utilise plusieurs technologies:",
          highlighted: [
            {
              text: "enrichir",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Il utilise GSAP pour créer des animations fluides et des effets visuels dynamiques, en maintenant de bonnes performances et améliorant l'attrait visuel et l'interactivité des interfaces.",
              highlighted: [
                {
                  text: "GSAP",
                  style: "highlight-underline2",
                },
                {
                  text: "animations fluides",
                  style: "highlight-bold2",
                },
                {
                  text: "performances",
                  style: "highlight-bold2",
                },
                {
                  text: "l'interactivité",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: gsap,
              imageSrcWebp: gsapWebp,
              imageAlt: "gsap",
              highlightText: "GreenSock",
              highlightPosition: { top: "-9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Avec CSS et HTML5, il se concentre sur l'utilisation de flexbox pour des interfaces propres et dynamiques, en maintenant un code modulaire grâce aux variables générales.",
              highlighted: [
                {
                  text: "CSS et HTML5",
                  style: "highlight-underline2",
                },
                {
                  text: "flexbox",
                  style: "highlight-bold2",
                },
                {
                  text: "propres",
                  style: "highlight-bold2",
                },
                {
                  text: "dynamiques",
                  style: "highlight-bold2",
                },
                {
                  text: "code modulaire",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: htmlcss,
              imageSrcWebp: htmlcssWebp,
              imageAlt: "htmlcss",
              highlightText: "CSS/HTML5",
              highlightPosition: { top: "-5vw", left: "52vw" },
              textPosition: "left",
            },
            {
              content:
                "Il connaît le framework Angular pour sa structure solide, la gestion des observables et des projets robustes. Il connaît React, avec ses hooks, optimisant la maintenance de ses applications.",
              highlighted: [
                {
                  text: "framework Angular",
                  style: "highlight-underline2",
                },
                {
                  text: "observables",
                  style: "highlight-bold2",
                },
                {
                  text: "React,",
                  style: "highlight-underline2",
                },
                {
                  text: "hooks",
                  style: "highlight-bold2",
                },
                {
                  text: "maintenance",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: reactangular,
              imageSrcWebp: reactangularWebp,
              imageAlt: "reactangular",
              highlightText: "Angular/React",
              highlightPosition: { top: "0vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Quand c'est nécessaire, il crée du code JavaScript personnalisé pour répondre aux besoins des projets, garantissant une expérience utilisateur précise, fluide et interactive.",
              highlighted: [
                {
                  text: "code JavaScript",
                  style: "highlight-underline2",
                },
                {
                  text: "besoins",
                  style: "highlight-bold2",
                },
                {
                  text: "précise",
                  style: "highlight-bold2",
                },
                {
                  text: "fluide",
                  style: "highlight-bold2",
                },
                {
                  text: "interactive",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: js,
              imageSrcWebp: jsWebp,
              imageAlt: "javaScript",
              highlightText: "JavaScript",
              highlightPosition: { top: "4vw", left: "53vw" },
              textPosition: "left",
            },
            {
              content:
                "Il possède aussi des compétences en développement Backend et une expérience solide en gestion de bases de données via SQL.",
              highlighted: [
                {
                  text: "Backend",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: sql,
              imageSrcWebp: sqlWebp,
              imageAlt: "sql",
              highlightText: "SQL/Backend",
              highlightPosition: { top: "9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Il maîtrise Git et insiste sur une architecture de commits et de branches bien organisées pour un développement efficace.",
              highlighted: [
                {
                  text: "Git",
                  style: "highlight-bold2",
                },
                {
                  text: "architecture",
                  style: "highlight-bold2",
                },
                {
                  text: "commits",
                  style: "highlight-italic2",
                },
                {
                  text: "branches",
                  style: "highlight-italic2",
                },
                {
                  text: "organisées",
                  style: "highlight-bold2",
                },
              ],
              imageSrc: git,
              imageSrcWebp: gitWebp,
              imageAlt: "git",
              highlightText: "Gestion Version",
              highlightPosition: { top: "15vw", left: "55vw" },
              textPosition: "left",
            },
          ],
        },
      ],
      character12: [
        {
          type: "subtitle",
          content: "Évolution et Apprentissage",
          highlighted: [
            {
              text: "Évolution",
              style: "highlight-underline2",
            },
            {
              text: "Apprentissage",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Se tenir à jour avec les nouvelles technologies et tendances est un défi que le Scrum Nomad relève constamment.",
              highlighted: [
                { text: "nouvelles", style: "highlight-bold2" },
                { text: "technologies", style: "highlight-bold2" },
                { text: "défi", style: "highlight-bold2" },
                { text: "relève", style: "highlight-bold2" },
                { text: "constamment", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le développement de ce site from scratch lui a permis d'apprendre de nouvelles technologies et frameworks.",
              highlighted: [
                { text: "from scratch", style: "highlight-italic2" },
                { text: "technologies", style: "highlight-bold2" },
                { text: "frameworks", style: "highlight-bold2" },
              ],
            },
            {
              text: "Il est toujours prêt à découvrir de nouvelles solutions pertinentes pour les projets sur lesquels il travaille.",
              highlighted: [
                { text: "nouvelles", style: "highlight-bold2" },
                { text: "solutions pertinentes", style: "highlight-bold2" },
              ],
            },
          ],
        },
        {
          type: "subtitle",
          content: "DoD et DoR Techniques",
          highlighted: [
            {
              text: "DoD",
              style: "highlight-bold2",
            },
            {
              text: "DoR",
              style: "highlight-bold2",
            },
            {
              text: "Techniques",
              style: "highlight-underline2",
            },
          ],
        },

        {
          type: "paragraph",
          content:
            "En tant que Scrum Master Technique, il met un point d'honneur à définir des DoD (Definition of Done) et DoR (Definition of Ready) incluant des critères techniques pour garantir des bonnes pratiques et une qualité optimale du code.",
          highlighted: [
            {
              text: "Scrum Master",
              style: "highlight-italic2",
            },
            {
              text: "Technique",
              style: "highlight-bold2",
            },
            {
              text: "DoD",
              style: "highlight-bold2",
            },
            {
              text: "DoR",
              style: "highlight-bold2",
            },
            {
              text: "critères techniques",
              style: "highlight-italic2",
            },
            {
              text: "bonnes pratiques",
              style: "highlight-italic2",
            },
            {
              text: "optimale",
              style: "highlight-italic2",
            },
          ],
        },
      ],
      //Role
      character13: [
        {
          type: "title",
          content: "Les rôles du Scrum Nomad",
          highlighted: [
            { text: "rôles", style: "highlight-underline" },
            { text: "Scrum Nomad", style: "highlight-slide" },
          ],
        },
        {
          type: "subtitle",
          content: "Les postures d'un Scrum Master Technique",
          highlighted: [
            {
              text: "postures",
              style: "highlight-bold",
            },
            {
              text: "Scrum Master Technique",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le rôle initial du Scrum Nomad, avec ses différentes compétences, est Scrum Master Technique.",
              highlighted: [
                { text: "rôle initial", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Technique", style: "highlight-bold" },
                { text: "rôle principal", style: "highlight-bold" },
              ],
            },
            {
              text: "Il apporte une valeur ajoutée significative à une équipe de développement grâce à ses compétences agiles et techniques.",
              highlighted: [
                { text: "valeur ajoutée", style: "highlight-bold" },
                { text: "compétences", style: "highlight-bold" },
                { text: "agiles", style: "highlight-bold" },
                { text: "techniques", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Voici comment son rôle de Scrum Master Technique se traduit concrètement dans la gestion et la réussite de vos projets:",
          highlighted: [
            { text: "Scrum", style: "highlight-bold" },
            { text: "Master Technique", style: "highlight-bold" },
            {
              text: "gestion",
              style: "highlight-bold",
            },
            {
              text: "réussite",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Compréhension des défis: Avec ses compétences en développement, il comprend correctement les problématiques de l'équipe, permettant une meilleure communication et résolution des problèmes.",
              highlighted: [
                {
                  text: "Compréhension des défis",
                  style: "highlight-underline",
                },
                {
                  text: "problématiques de l'équipe",
                  style: "highlight-bold",
                },
                {
                  text: "meilleure",
                  style: "highlight-bold",
                },
                {
                  text: "communication",
                  style: "highlight-bold",
                },
                {
                  text: "résolution",
                  style: "highlight-bold",
                },
              ],
              imageSrc: understandingTech,
              imageSrcWebp: understandingTechWebp,
              imageAlt: "understandingTech",
              highlightText: "Compréhension techniques",
              highlightPosition: { top: "-14vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Facilitation des problèmes: Son expertise technique permet de trouver rapidement des solutions aux obstacles rencontrés, facilitant ainsi la progression du projet.",
              highlighted: [
                {
                  text: "Facilitation des problèmes:",
                  style: "highlight-underline",
                },
                {
                  text: "solutions",
                  style: "highlight-bold",
                },
                {
                  text: "facilitant",
                  style: "highlight-italic",
                },
                {
                  text: "progression",
                  style: "highlight-italic",
                },
              ],
              imageSrc: facilitationResolution,
              imageSrcWebp: facilitationResolutionWebp,
              imageAlt: "facilitationResolution",
              highlightText: "Facilitation obstacles",
              highlightPosition: { top: "-8vw", left: "57vw" },
              textPosition: "left",
            },
            {
              content:
                "Meilleurs PBI: le Scrum Nomad est capable d'aider pour des estimations d'effort plus précises, assurant une planification fiable.",
              highlighted: [
                {
                  text: "Meilleurs PBI:",
                  style: "highlight-underline",
                },
                {
                  text: "d'aider",
                  style: "highlight-italic",
                },
                {
                  text: "estimations",
                  style: "highlight-bold",
                },
                {
                  text: "planification fiable",
                  style: "highlight-italic",
                },
              ],
              imageSrc: effortEstimation,
              imageSrcWebp: effortEstimationWebp,
              imageAlt: "effortEstimation",
              highlightText: "Meilleure estimation",
              highlightPosition: { top: "-2vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Mentorat technique: Il guide les membres de l'équipe dans l'adoption des bonnes pratiques de développement, améliorant ainsi la qualité du code et l'efficacité de l'équipe.",
              highlighted: [
                {
                  text: "Mentorat technique:",
                  style: "highlight-underline",
                },
                {
                  text: "guide",
                  style: "highlight-italic",
                },
                {
                  text: "bonnes pratiques",
                  style: "highlight-italic",
                },
                {
                  text: "qualité du code",
                  style: "highlight-bold",
                },
                {
                  text: "efficacité de l'équipe",
                  style: "highlight-bold",
                },
              ],
              imageSrc: technicalCoaching,
              imageSrcWebp: technicalCoachingWebp,
              imageAlt: "technicalCoaching",
              highlightText: "Coaching technique",
              highlightPosition: { top: "4vw", left: "58vw" },
              textPosition: "left",
            },
            {
              content:
                "Médiation au sein du projet: En comprenant les exigences techniques et les objectifs commerciaux, il joue un rôle crucial dans la médiation et facilitation des discussions.",
              highlighted: [
                {
                  text: "Médiation au sein du projet:",
                  style: "highlight-underline",
                },
                {
                  text: "exigences",
                  style: "highlight-bold",
                },
                {
                  text: "objectifs",
                  style: "highlight-bold",
                },
                {
                  text: "médiation et facilitation",
                  style: "highlight-italic",
                },
              ],
              imageSrc: mediation,
              imageSrcWebp: mediationWebp,
              imageAlt: "mediation",
              highlightText: "Médiation",
              highlightPosition: { top: "9vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Amélioration continue: Il identifie des opportunités d'amélioration, favorise la refactorisation quand nécessaire, et intègre rapidement les nouvelles technologies pour optimiser les méthodes de travail.",
              highlighted: [
                {
                  text: "Amélioration continue:",
                  style: "highlight-underline",
                },
                {
                  text: "d'amélioration",
                  style: "highlight-bold",
                },
                {
                  text: "refactorisation",
                  style: "highlight-italic",
                },
                {
                  text: "nouvelles",
                  style: "highlight-bold",
                },
                {
                  text: "technologies",
                  style: "highlight-bold",
                },
              ],
              imageSrc: continuousImprovement,
              imageSrcWebp: continuousImprovementWebp,
              imageAlt: "continuousImprovement",
              highlightText: "Amélioration continue",
              highlightPosition: { top: "14vw", left: "56vw" },
              textPosition: "left",
            },
            {
              content:
                "Meilleure gestion d'obstacles: Son expertise technique lui permet d'identifier et de surmonter rapidement les obstacles techniques, minimisant les retards et les interruptions.",
              highlighted: [
                {
                  text: "Meilleure gestion d'obstacles:",
                  style: "highlight-underline",
                },
                {
                  text: "d'identifier",
                  style: "highlight-bold",
                },
                {
                  text: "minimisant les retards",
                  style: "highlight-bold",
                },
                {
                  text: "interruptions",
                  style: "highlight-bold",
                },
              ],
              imageSrc: obstacleManagement,
              imageSrcWebp: obstacleManagementWebp,
              imageAlt: "obstacleManagement",
              highlightText: "Gestion d'obstacles",
              highlightPosition: { top: "20vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Confiance et crédibilité: En tant que Scrum Master Technique, il inspire confiance et crédibilité au sein de l'équipe, favorisant un environnement de travail collaboratif et productif.",
              highlighted: [
                {
                  text: "Confiance et crédibilité:",
                  style: "highlight-underline",
                },
                {
                  text: "collaboratif",
                  style: "highlight-italic",
                },
                {
                  text: "productif",
                  style: "highlight-italic",
                },
              ],
              imageSrc: trustCredibility,
              imageSrcWebp: trustCredibilityWebp,
              imageAlt: "trustCredibility",
              highlightText: "Confiance",
              highlightPosition: { top: "25vw", left: "58vw" },
              textPosition: "left",
            },
          ],
        },
      ],
      character14: [
        {
          type: "subtitle",
          content: "Quels rôles peut-il endosser dans vos projets ?",
          highlighted: [
            {
              text: "Quels rôles",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Les différentes postures du Scrum Nomad permet de jouer efficacement le rôle de servant leader.",
              highlighted: [
                { text: "postures", style: "highlight-bold" },
                { text: "servant", style: "highlight-italic" },
                { text: "leader", style: "highlight-italic" },
              ],
            },
            {
              text: "Il guide son équipe vers l'excellence, en maximisant la valeur délivrée aux clients et aux parties prenantes.",
              highlighted: [
                { text: "guide", style: "highlight-italic" },
                { text: "l'excellence", style: "highlight-bold" },
                { text: "maximisant", style: "highlight-bold" },
                { text: "valeur", style: "highlight-bold" },
              ],
            },

            {
              text: "Avec ces compétences et ces postures, il est capable de s'adapter aux situations et aux besoins de l'entreprise, adaptant son approche au cas par cas.",
              highlighted: [
                { text: "s'adapter", style: "highlight-bold" },
                { text: "besoins", style: "highlight-bold" },
                { text: "cas par cas", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Mais concrètement, être servent leader lui permet de pouvoir assumer quels rôles ?",
          highlighted: [
            {
              text: "concrètement",
              style: "highlight-bold",
            },
            {
              text: "servent",
              style: "highlight-italic",
            },
            {
              text: "leader",
              style: "highlight-italic",
            },
            {
              text: "quels rôles ?",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "highlight",
          highlights: [
            {
              content:
                "Scrum Master Technique: Apporter une compréhension agile et technique pour améliorer les processus de développement et la résolution de problèmes.",
              highlighted: [
                {
                  text: "Scrum Master Technique:",
                  style: "highlight-underline",
                },
                {
                  text: "agile",
                  style: "highlight-bold",
                },
                {
                  text: "technique",
                  style: "highlight-bold",
                },
                {
                  text: "processus",
                  style: "highlight-italic",
                },
                {
                  text: "résolution de problèmes",
                  style: "highlight-italic",
                },
              ],
              imageSrc: scrumMasterTech,
              imageSrcWebp: scrumMasterTechWebp,
              imageAlt: "scrumMasterTech",
              highlightText: "S.M.T.",
              highlightPosition: { top: "-7vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Consultant Agile: Offrir des conseils stratégiques sur la mise en œuvre et l'amélioration des pratiques agiles au sein de l'organisation.",
              highlighted: [
                {
                  text: "Consultant Agile:",
                  style: "highlight-underline",
                },
                {
                  text: "conseils stratégiques",
                  style: "highlight-bold",
                },
                {
                  text: "pratiques agiles",
                  style: "highlight-bold",
                },
              ],
              imageSrc: agileAdvisor,
              imageSrcWebp: agileAdvisorWebp,
              imageAlt: "agileAdvisor",
              highlightText: "Consultant",
              highlightPosition: { top: "-2vw", left: "52vw" },
              textPosition: "left",
            },
            {
              content:
                "Formateur Scrum: Animer des sessions de formation pour former les équipes aux méthodologies Scrum et renforcer leurs compétences.",
              highlighted: [
                {
                  text: "Formateur Scrum:",
                  style: "highlight-underline",
                },
                {
                  text: "formation",
                  style: "highlight-bold",
                },
                {
                  text: "méthodologies Scrum",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumTrainer,
              imageSrcWebp: scrumTrainerWebp,
              imageAlt: "scrumTrainer",
              highlightText: "Formateur",
              highlightPosition: { top: "3vw", left: "39vw" },
              textPosition: "right",
            },
            {
              content:
                "Coach Scrum: Travailler en étroite collaboration avec les équipes pour adopter et améliorer les pratiques Scrum, en soutenant leur développement continu.",
              highlighted: [
                {
                  text: "Coach Scrum:",
                  style: "highlight-underline",
                },
                {
                  text: "collaboration",
                  style: "highlight-italic",
                },
                {
                  text: "adopter",
                  style: "highlight-bold",
                },
                {
                  text: "améliorer",
                  style: "highlight-italic",
                },
                {
                  text: "les pratiques Scrum",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumCoach,
              imageSrcWebp: scrumCoachWebp,
              imageAlt: "scrumCoach",
              highlightText: "Coach",
              highlightPosition: { top: "7.5vw", left: "56vw" },
              textPosition: "left",
            },
            {
              content:
                "Auditeur Scrum: Évaluer la maturité Scrum de projets et proposer des recommandations pour améliorer l'efficacité et la performance des équipes.",
              highlighted: [
                {
                  text: "Auditeur Scrum:",
                  style: "highlight-underline",
                },
                {
                  text: "maturité Scrum",
                  style: "highlight-bold",
                },
                {
                  text: "recommandations",
                  style: "highlight-bold",
                },
              ],
              imageSrc: scrumAuditor,
              imageSrcWebp: scrumAuditorWebp,
              imageAlt: "htmlcss",
              highlightText: "Auditeur",
              highlightPosition: { top: "12vw", left: "39vw" },
              textPosition: "right",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Ces multiples compétences, rôles et postures permettent au Scrum Nomad de s'adapter aux besoins spécifiques de chaque organisation, assurant une approche flexible.",
          highlighted: [
            {
              text: "compétences",
              style: "highlight-italic",
            },
            {
              text: "rôles",
              style: "highlight-italic",
            },
            {
              text: "postures",
              style: "highlight-italic",
            },

            {
              text: "besoins spécifiques",
              style: "highlight-bold",
            },
            {
              text: "flexible",
              style: "highlight-bold",
            },
          ],
        },
      ],
      //Exp
      character15: [
        {
          type: "title",
          content: "Projets et Réalisations",
          highlighted: [
            { text: "Projets", style: "highlight-underline" },
            { text: "Réalisations", style: "highlight-underline" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Salut, je suis ProjectBot, je suis chargé de vous présenter les projets et réalisations du Scrum Nomad. Voici un aperçu de ses expériences professionnelles et des solutions qu'il a apportées.",
          highlighted: [
            {
              text: "ProjectBot",
              style: "highlight-italic",
            },
            {
              text: "projets",
              style: "highlight-bold",
            },
            {
              text: "réalisations",
              style: "highlight-bold",
            },
            {
              text: "ses expériences",
              style: "highlight-bold",
            },
            {
              text: "professionnelles",
              style: "highlight-bold",
            },
            {
              text: "solutions",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Projets Professionnels",
          highlighted: [
            {
              text: "Projets Professionnels",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad a occupé divers rôles clés dans des projets de développement complexes, notamment en tant que Lead Scrum Master, Scrum Master et Développeur.",
              highlighted: [
                { text: "rôles clés", style: "highlight-bold" },
                { text: "projets de", style: "highlight-italic" },
                { text: "développement", style: "highlight-italic" },
                { text: "Lead", style: "highlight-bold" },
                { text: "Scrum Master", style: "highlight-bold" },
                { text: "Développeur", style: "highlight-bold" },
              ],
            },
            {
              text: "Son expertise a permis de transformer les équipes et les processus pour atteindre des résultats significatifs.",
              highlighted: [
                { text: "transformer", style: "highlight-bold" },
                { text: "résultats", style: "highlight-bold" },
                { text: "significatifs", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Projet e-GMAO",
          highlighted: [
            { text: "Projet e-GMAO", style: "highlight-subsubtile" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "En tant que Lead Scrum Master, il a adapté le fonctionnement de deux équipes conjointes et amélioré leur maturité et autonomie.",
              highlighted: [
                { text: "Lead Scrum Master", style: "highlight-italic" },
                { text: "adapté", style: "highlight-italic" },
                { text: "deux équipes conjointes", style: "highlight-bold" },
                { text: "amélioré", style: "highlight-italic" },
                { text: "maturité", style: "highlight-bold" },
                { text: "autonomie", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a refactorisé les processus Scrum pour une meilleure communication et mis en place des outils pour augmenter la clarté et l'efficacité.",
              highlighted: [
                { text: "refactorisé", style: "highlight-bold" },
                { text: "meilleure", style: "highlight-bold" },
                { text: "communication", style: "highlight-bold" },
                { text: "clarté", style: "highlight-bold" },
                { text: "l'efficacité", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a également mis en place une transition vers Kanban ce qui a permis d'augmenter la fluidité et de mieux délivrer de la valeur.",
              highlighted: [
                { text: "transition", style: "highlight-bold" },
                { text: "Kanban", style: "highlight-italic" },
                { text: "fluidité", style: "highlight-bold" },
                { text: "mieux délivrer", style: "highlight-bold" },
                { text: "valeur", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Projet CNM",
          highlighted: [{ text: "Projet CNM", style: "highlight-subsubtile" }],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Il a dirigé la refonte d'une application AngularJS obsolète. ",
              highlighted: [
                { text: "refonte", style: "highlight-italic" },
                { text: "AngularJS", style: "highlight-bold" },
                { text: "obsolète", style: "highlight-bold" },
              ],
            },
            {
              text: "En supervisant le processus de planification et de livraison, il a respecté des délais serrés et coaché un Product Owner junior pour améliorer la rédaction des PBI et la priorisation des besoins.",
              highlighted: [
                { text: "planification", style: "highlight-bold" },
                { text: "livraison", style: "highlight-bold" },
                { text: "coaché", style: "highlight-italic" },
                { text: "Product Owner", style: "highlight-italic" },
                { text: "rédaction des PBI", style: "highlight-bold" },
                { text: "priorisation des besoins", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a également recadré la notion de valeur dans l'équipe, travaillant sur la réelle valeur des PBI.",
              highlighted: [
                { text: "recadré", style: "highlight-bold" },
                { text: "valeur", style: "highlight-bold" },
                { text: "PBI", style: "highlight-italic" },
              ],
            },
            {
              text: "Il a fait un travail de clarification de la valeur, aidant l'équipe de développement à mieux s'organiser pour les solutions et aidant le PO à mieux organiser le backlog.",
              highlighted: [
                { text: "clarification de la valeur", style: "highlight-bold" },
                { text: "organiser le backlog", style: "highlight-bold" },
              ],
            },
            {
              text: "Grâce à ces différentes actions, la vélocité de l'équipe a augmenté de 70%.",
              highlighted: [
                { text: "vélocité", style: "highlight-bold" },
                { text: "augmenté", style: "highlight-bold" },
                { text: "70%", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "subsubtitle",
          content: "Projet REXEL",
          highlighted: [
            { text: "Projet REXEL", style: "highlight-subsubtile" },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Comme développeur, il a refactorisé une application PHP obsolète en intégrant le framework Symfony pour améliorer la maintenabilité et la sécurité.",
              highlighted: [
                { text: "développeur", style: "highlight-bold" },
                { text: "refactorisé", style: "highlight-italic" },
                { text: "application PHP", style: "highlight-bold" },
                { text: "obsolète", style: "highlight-bold" },
                { text: "framework Symfony", style: "highlight-italic" },
                { text: "maintenabilité", style: "highlight-bold" },
                { text: "sécurité", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a collaboré étroitement avec l'équipe précédente pour un transfert de connaissances efficace et mis en œuvre de nouvelles fonctionnalités tout en facilitant les processus de déploiement.",
              highlighted: [
                { text: "collaboré", style: "highlight-bold" },
                { text: "transfert de connaissances", style: "highlight-bold" },
                { text: "efficace", style: "highlight-bold" },
                { text: "nouvelles fonctionnalités", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      character16: [
        {
          type: "subtitle",
          content: "Projet Personnel",
          highlighted: [
            {
              text: "Projet Personnel",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le plus gros projet personnel du Scrum Nomad est ce monde virtuel dans lequel vous êtes.",
              highlighted: [
                { text: "plus gros projet personnel", style: "highlight-bold" },
                { text: "ce monde virtuel ", style: "highlight-bold" },
                { text: "Scrum Nomad", style: "highlight-slide" },
              ],
            },
            {
              text: "Il a developpé ce site de sa conception à sa finition, en passsant par le design, le code ou de l'architecture, seul.",
              highlighted: [
                { text: "conception", style: "highlight-bold" },
                { text: "finition", style: "highlight-bold" },
                { text: "design", style: "highlight-bold" },
                { text: "code", style: "highlight-bold" },
                { text: "l'architecture", style: "highlight-bold" },
                { text: "seul", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le site du Scrum Nomad a été conçu avec une attention particulière à la performance et à l'optimisation. Voici quelques détails techniques:",
              highlighted: [
                { text: "performance", style: "highlight-bold" },
                { text: "l'optimisation", style: "highlight-italic" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "Technologies utilisées: React, GSAP, CSS, HTML5, JavaScript.",
              highlighted: [
                {
                  text: "Technologies utilisées:",
                  style: "highlight-underline",
                },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "Conception et Architecture: Le site utilise une architecture modulaire avec des composants réutilisables, permettant une maintenance aisée et une évolutivité.",
              highlighted: [
                {
                  text: "Conception et Architecture:",
                  style: "highlight-underline",
                },
                { text: "modulaire", style: "highlight-italic" },
                { text: "réutilisables", style: "highlight-bold" },
                { text: "maintenance aisée", style: "highlight-bold" },
                { text: "évolutivité", style: "highlight-bold" },
              ],
            },
            {
              text: "La conception est centrée sur une navigation fluide et intuitive. La structure du site repose sur un système de gestion de l'état efficace pour synchroniser les différentes animations et interactions utilisateur.",
              highlighted: [
                { text: "navigation fluide", style: "highlight-bold" },
                { text: "intuitive", style: "highlight-bold" },
                { text: "gestion de l'état", style: "highlight-bold" },
              ],
            },
            {
              text: "Toutes les images ont été générées puis modifiées par intelligence artificielle. MidJourney pour les images créatives, DALL·E pour les icônes, et Photoshop AI pour les modifications.",
              highlighted: [
                { text: "intelligence artificielle", style: "highlight-bold" },
                { text: "midjourney", style: "highlight-bold" },
                { text: "DALL·E", style: "highlight-bold" },
                { text: "photoshop AI", style: "highlight-bold" },
              ],
            },
            {
              text: "Chacune de ces images a ensuite été individuellement retravaillée et optimisée selon les besoins du Scrum Nomad, pour obtenir le rendu recherché.",
              highlighted: [
                { text: "individuellement", style: "highlight-bold" },
                { text: "retravaillée", style: "highlight-bold" },
                { text: "optimisée", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Optimisation des performances: Mise en place du LazyLoading ainsi qu'un chargement séquencé des images prioritaires, utilisation du format WebP pour réduire le volume des images, et implémentation d'un système de cache léger.",
              highlighted: [
                {
                  text: "Optimisation des performances:",
                  style: "highlight-underline",
                },
                { text: "LazyLoading", style: "highlight-bold" },
                { text: "chargement", style: "highlight-bold" },
                { text: "format WebP", style: "highlight-bold" },
                { text: "cache", style: "highlight-bold" },
              ],
            },
            {
              text: "Les effets visuels et d'animations sont orchestrés via GSAP pour garantir des transitions fluides et une performance optimale.",
              highlighted: [
                { text: "effets visuels", style: "highlight-bold" },
                { text: "GSAP", style: "highlight-bold" },
                { text: "transitions fluides", style: "highlight-bold" },
                { text: "performance optimale", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Pratiques de développement: Adoption de pratiques agiles avec un backlog bien défini, une estimation précise des tâches et une attention particulière aux DoD et DoR pour garantir la qualité et la maintenabilité du code.",
              highlighted: [
                {
                  text: "Pratiques de développement:",
                  style: "highlight-underline",
                },
                { text: "pratiques agiles", style: "highlight-bold" },
                { text: "backlog", style: "highlight-italic" },
                { text: "estimation", style: "highlight-bold" },
                { text: "DoD", style: "highlight-italic" },
                { text: "DoR", style: "highlight-italic" },
                { text: "qualité", style: "highlight-bold" },
                { text: "maintenabilité", style: "highlight-bold" },
              ],
            },
          ],
        },

        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le site a également servi de terrain d'apprentissage pour de nombreuses technologies. Le Scrum Nomad a testé différentes solutions pour trouver les meilleures approches.",
              highlighted: [
                { text: "technologies", style: "highlight-bold" },
                { text: "solutions", style: "highlight-bold" },
                { text: "meilleurs approches", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a créé lui-même l'entièreté des animations du site, préférant contrôler chaque aspect pour mieux comprendre leur fonctionnement.",
              highlighted: [
                { text: "lui-même", style: "highlight-bold" },
                { text: "contrôler", style: "highlight-bold" },
                { text: "mieux comprendre", style: "highlight-bold" },
              ],
            },
            {
              text: "Ce processus lui a permis de comprendre GSAP et d'améliorer continuellement les performances du site.",
              highlighted: [
                { text: "comprendre GSAP", style: "highlight-bold" },
                {
                  text: "d'améliorer",
                  style: "highlight-bold",
                },
                { text: "performances", style: "highlight-bold" },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Il est évidemment conscient que les méthodes utilisées ne sont pas forcément les plus adaptées, étant persuadé qu'il est toujours possible de faire mieux.",
          highlighted: [
            { text: "persuadé", style: "highlight-bold" },
            { text: "toujours possible", style: "highlight-bold" },
            { text: "faire mieux", style: "highlight-bold" },
          ],
        },
      ],
      character17: [
        {
          type: "subtitle",
          content: "Autres Expériences",
          highlighted: [
            {
              text: "Autres Expériences",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Le Scrum Nomad a également un parcours académique riche en géologie, avec des expériences variées en contextes différents.",
              highlighted: [
                { text: "parcours académique riche", style: "highlight-bold" },
                { text: "géologie", style: "highlight-italic" },
                { text: "expériences variées", style: "highlight-bold" },
              ],
            },
            {
              text: "Il a effectué des stages à l'île de la Réunion, travaillant sur le volcan actif du Piton de la Fournaise, ainsi qu'en Grèce pour étudier la géologie des Cyclades.",
              highlighted: [
                { text: "l'île de la Réunion", style: "highlight-bold" },
                { text: "le volcan actif", style: "highlight-bold" },
                { text: "Grèce", style: "highlight-bold" },
                { text: "Cyclades", style: "highlight-bold" },
              ],
            },
            {
              text: "Passionné de pétrologie, il a aussi beaucoup travaillé en laboratoire pour l'analyse géochimique et structurelle de différentes roches.",
              highlighted: [
                { text: "pétrologie", style: "highlight-bold" },
                { text: "laboratoire", style: "highlight-bold" },
                { text: "géochimique", style: "highlight-bold" },
                { text: "structurelle", style: "highlight-bold" },
              ],
            },
            {
              text: "Ces expériences lui ont appris la rigueur et la méthodologie nécessaires pour trouver des solutions à tout challenge, apportant une approche méthodique et concentrée à ses projets.",
              highlighted: [
                { text: "expériences", style: "highlight-bold" },
                { text: "rigueur", style: "highlight-bold" },
                { text: "méthodologie", style: "highlight-bold" },
                { text: "challenge", style: "highlight-bold" },
                { text: "méthodique", style: "highlight-bold" },
                { text: "concentrée", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      character18: [
        {
          type: "subtitle",
          content: "Témoignages et Références",
          highlighted: [
            {
              text: "Témoignages et Références",
              style: "highlight-underline",
            },
          ],
        },
        {
          type: "paragraphGroup",
          content: [
            {
              text: "Les témoignages et références de collègues et clients soulignent l'impact positif du Scrum Nomad sur leurs projets.",
              highlighted: [
                { text: "l'impact positif", style: "highlight-italic" },
              ],
            },
            {
              text: "Son approche méthodique, son expertise technique et sa capacité à coacher les équipes sont salués.",
              highlighted: [
                { text: "méthodique", style: "highlight-bold" },
                { text: "expertise technique", style: "highlight-bold" },
                { text: "capacité à coacher", style: "highlight-bold" },
              ],
            },
            {
              text: "Des recommandations supplémentaires sont disponibles sur demande. Pour obtenir ces informations, vous pouvez le contacter via le formulaire de contact disponible sur ce site.",
              highlighted: [
                { text: "sur demande", style: "highlight-bold" },
                { text: "formulaire de contact", style: "highlight-bold" },
              ],
            },
          ],
        },
      ],
      //Parcours
      character19: [
        {
          type: "title",
          content: "Parcours académique",
          highlighted: [
            { text: "Parcours académique", style: "highlight-underline2" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Salut, je suis EducBot, chargé de vous présenter le parcours académique et les bases des compétences du Scrum Nomad. Voici un aperçu de ses certifications, formations continues et diplômes.",
          highlighted: [
            {
              text: "EducBot",
              style: "highlight-italic2",
            },
            {
              text: "parcours académique",
              style: "highlight-bold2",
            },
            {
              text: "bases des compétences",
              style: "highlight-bold2",
            },
            {
              text: "certifications",
              style: "highlight-bold2",
            },
            {
              text: "formations",
              style: "highlight-bold2",
            },
            {
              text: "continues",
              style: "highlight-bold2",
            },
            {
              text: "diplômes",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Certifications Professionnelles",
          highlighted: [
            {
              text: "Certifications",
              style: "highlight-underline2",
            },
            {
              text: "Professionnelles",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Pour appuyer sa transition vers le domaine de l'agilité et du développement, le Scrum Nomad a obtenu plusieurs certifications et formations continues:",
          highlighted: [
            {
              text: "certifications",
              style: "highlight-bold2",
            },
            {
              text: "formations continues:",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: [
            "Certification Scrum Master - Janvier 2021. Formation certifiante Scrum Master",
            "Leading SAFe - Novembre 2021. Formation sur l'environnement SAFe et la posture à avoir",
            "Animer classe virtuelle - Février 2022. Formation pour comprendre et animer des classes virtuelles.",
            "Argumenter pour convaincre - Décembre 2021. Formation pour structurer des arguments solides et convaincre efficacement dans un cadre professionnel.",
          ],
          highlighted: [
            {
              text: "Certification Scrum Master",
              style: "highlight-underline2",
            },
            { text: "Leading SAFe", style: "highlight-underline2" },
            {
              text: "Animer classe virtuelle",
              style: "highlight-underline2",
            },
            {
              text: "Argumenter pour convaincre",
              style: "highlight-underline2",
            },
            {
              text: "certifiante",
              style: "highlight-bold2",
            },
            {
              text: "l'environnement SAFe",
              style: "highlight-bold2",
            },
            {
              text: "posture",
              style: "highlight-bold2",
            },
            {
              text: "comprendre",
              style: "highlight-bold2",
            },
            {
              text: "animer",
              style: "highlight-bold2",
            },
            {
              text: "structurer",
              style: "highlight-bold2",
            },
            {
              text: "arguments",
              style: "highlight-bold2",
            },
            {
              text: "convaincre",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "subtitle",
          content: "Formation en tant que Formateur",
          highlighted: [
            {
              text: "Formation",
              style: "highlight-underline2",
            },
            {
              text: "Formateur",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Le Scrum Nomad a animé des classes virtuelles pour former les participants aux pratiques agiles et Scrum en particulier",
          highlighted: [
            {
              text: "animé",
              style: "highlight-bold2",
            },
            {
              text: "classes virtuelles",
              style: "highlight-bold2",
            },
            {
              text: "pratiques",
              style: "highlight-bold2",
            },
            {
              text: "agiles et Scrum",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: ["Méthode agile Scrum Master - Avril 2022"],
          highlighted: [
            {
              text: "Méthode agile Scrum Master",
              style: "highlight-underline2",
            },
          ],
        },
      ],
      character20: [
        {
          type: "subtitle",
          content: "Diplômes Académiques",
          highlighted: [
            {
              text: "Diplômes Académiques",
              style: "highlight-underline2",
            },
          ],
        },
        {
          type: "paragraph",
          content:
            "Le Scrum Nomad possède également une solide formation académique en sciences, et en particulier dans la recherche en géologie",
          highlighted: [
            {
              text: "formation académique",
              style: "highlight-bold2",
            },
            {
              text: "sciences",
              style: "highlight-bold2",
            },
            {
              text: "recherche en géologie",
              style: "highlight-bold2",
            },
          ],
        },
        {
          type: "list",
          content: [
            "Master Géoressources et Géomatériaux - 2018. Université de Toulouse, France",
            "Master Dynamiques internes de la Terre - 2017. Centre de recherche en sciences de l'Univers, Université d'Orléans, France",
            "Licence Géochimie et pétrologie - 2015. Université Blaise Pascal, Clermont-Ferrand, France",
            "Licence Hydrologie et Sédimentologie - 2014. Université François Rabelais, Tours, France",
          ],
          highlighted: [
            { text: "Master", style: "highlight-bold2" },
            { text: "Licence", style: "highlight-bold2" },
            { text: "Géoressources", style: "highlight-bold2" },
            { text: "Géomatériaux", style: "highlight-bold2" },
            { text: "Dynamiques internes", style: "highlight-bold2" },
            { text: "Géochimie", style: "highlight-bold2" },
            { text: "Pétrologie", style: "highlight-bold2" },
            { text: "Hydrologie", style: "highlight-bold2" },
            { text: "Sédimentologie", style: "highlight-bold2" },
          ],
        },
      ],
      character21: [
        {
          type: "title",
          content: "Contact",
          highlighted: [{ text: "Contact", style: "highlight-underline" }],
        },
        {
          type: "paragraph",
          content:
            "Pour entrer en contact avec lui, utilisez le formulaire ci-dessous. Si vous préférez, vous pouvez également le joindre via LinkedIn ou sur X.",
          highlighted: [
            {
              text: "contact",
              style: "highlight-bold",
            },
            {
              text: "formulaire",
              style: "highlight-bold",
            },
            {
              text: "LinkedIn",
              style: "highlight-bold",
            },
            {
              text: "X",
              style: "highlight-bold",
            },
          ],
        },
        {
          type: "socialLink",
          content: {
            href: "https://www.linkedin.com/in/pierre-filliau/",
            srcPng: linkedin,
            srcWebp: linkedinWebp,
            alt: "LinkedIn",
            text: "LinkedIn du Scrum Nomad",
          },
          highlighted: [],
        },
        {
          type: "socialLink",
          content: {
            href: "https://x.com/PierreF34083004",
            srcPng: twitter,
            srcWebp: twitterWebp,
            alt: "twitterX",
            text: "Scrum Nomad X",
          },
          highlighted: [],
        },
        {
          type: "paragraph",
          content:
            "Que vous ayez des questions, un projet à proposer, ou que vous recherchiez ses services pour du coaching Scrum, une formation de Scrum Master, ou pour renforcer votre équipe, il se fera un plaisir de vous répondre dès que possible.",
          highlighted: [
            {
              text: "questions",
              style: "highlight-bold",
            },
            {
              text: "projet à proposer",
              style: "highlight-bold",
            },
            {
              text: "coaching Scrum",
              style: "highlight-bold",
            },
            {
              text: "formation",
              style: "highlight-bold",
            },
            {
              text: "Scrum Master",
              style: "highlight-bold",
            },
            {
              text: "renforcer votre équipe",
              style: "highlight-bold",
            },
            {
              text: "dès que possible",
              style: "highlight-bold",
            },
          ],
        },
      ],
      character22: [
        {
          type: "subtitle",
          content: "Voulez-vous télécharger le CV du Scrum Nomad ?",
          highlighted: [
            { text: "télécharger", style: "highlight-bold" },
            { text: "le CV", style: "highlight-bold" },
          ],
        },
        {
          type: "paragraph",
          content:
            "Pour ce faire, il suffit de cliquer sur le bouton à côté de moi.",
          highlighted: [
            {
              text: "cliquer",
              style: "highlight-bold",
            },
            {
              text: "bouton",
              style: "highlight-bold",
            },
          ],
        },
      ],
    },
    hovers: {
      musicStart: "Lancer Musique",
      musicStop: "Pause Musique",
      langToFrench: "Passer en Français",
      scrollDown: "Mode découverte",
      fullScreenON: "Plein écran",
      fullScreenOFF: "Quitter le plein écran",
      viewSection: "Voir la Section complète",
      closeBubble: "Fermer la Bulle",
      closeSection: "Fermer la Section",
      restart: "Recommencer",
      nextSlide: "Suivant",
      prevSlide: "Précédent",
      hoverMessages: [
        "Comment ça va ?",
        "Ravi de vous voir !",
        "Bip Bop",
        "Bop",
        "0001100010 01101111 01110000",
        "⏚⟒⍀ ⏚⍜⍀",
        "⏚⍜⎐⟟⍜⎍⍀",
        "Hey, clic sur cette bulle !",
        "...traduction en cours...",
        "Ou sont mon rouage préféré?",
        "Hey, j'ai quelque chose à dire !",
        "Il me manque une vis non ?",
        "Je suis votre guide galactique",
        "404 Message Not Found",
        "Vous avez survolé sur le bon robot",
        "Robot amical, humain approuvé",
        "Est-ce que vous avez vu ma vis ?",
        "Je connais tous les secrets de ce site",
        "Le futur, c'est maintenant! Euh, non attendez...",
        "Je suis plus intelligent qu'un grille-pain",
        "Je parle couramment binaire",
      ],
    },
    geology: {
      title: "Page de Géologie",
      subtitle: "Rien d'autre qu'une page...",
    },
    loadingPage: {
      howToInterractWebsite: "Comment interagir avec le site web ?",
      minimap: "Mini-carte",
      discovery: "Mode découverte",
      expand: "Plein écran",
      lang: "Changer la langue",
      music: "Couper la musique",
      howToInterractCharacter: "Comment interagir avec le site robot ?",
      nextSlide: "Poursuivre la lecture",
      seeSection: "Ouvrir la section entière",
      closeBubble: "Fermer la bulle",
    },
    contactForm: {
      title: "Contactez le Scrum Nomad",
      buttonSend: "Envoyer",
      placeholderName: "Nom",
      placeholderMail: "Email",
      placeholderObject: "Objet",
      placeholderMessage: "Votre message...",
      successMessage: "Votre message a été envoyé avec succès !",
      errorMessage: "Une erreur est survenue. Veuillez réessayer plus tard.",
      downloadResume: {
        downloadTooltip: "Téléchargez mon CV",
        confirmPart1: "Voulez-vous vraiment",
        confirmPart2: "télécharger mon CV ?",
        confirmYes: "Oui",
        confirmNo: "Non",
      },
    },
    otherTexts: { lastSlideMessage: "" },
  },
};
